import React, { ReactNode, ErrorInfo } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import ErrorIcon from "./ErrorIcon";
import AppCard from "../AppCard";

class AppErrorBoundary extends React.Component<
  { children: ReactNode },
  { hasError: boolean; errorText: string; errorInfo: ErrorInfo | null }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorText: "", errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    console.log("error: ", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorText: error as string };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
            textAlign: "center",
            "& svg": {
              width: "100%",
              maxWidth: 400,
              color: (theme) => theme.palette.primary.main,
            },
          }}
        >
          <ErrorIcon />
          <Typography
            variant="h2"
            component="h2"
            style={{ fontSize: 30, marginTop: 16 }}
          >
            Ah! Something went wrong.
          </Typography>
          <Typography style={{ fontSize: 18, marginTop: 12 }}>
            Brace yourself till we get the error fixed.
          </Typography>
          <Typography style={{ fontSize: 18 }}>
            You may also refresh the page or try again latter
          </Typography>

          <AppCard
            title="Veuillez copier ce méssage et envoyer à support@wizestack.com"
            sx={{ mt: 5 }}
          >
            <h1>Trace</h1>

            {this.state.errorText.toString()}
            {this.state.errorInfo
              ? (this.state.errorInfo.componentStack as string)
              : ""}

            <h1>Informations sur le navigateur</h1>
            <p>User Agent: {window.navigator.userAgent}</p>

            <h1>Date</h1>
            {new Date().toString()}
          </AppCard>
        </Box>
      );
    } else {
      return this.props.children;
    }
  }
}

export default AppErrorBoundary;

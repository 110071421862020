import React from "react";
import ReactDOM from "react-dom";
import "@crema/services";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react";
import Hotjar from "@hotjar/browser";
import dotenv from "dotenv";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

dotenv.config();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const siteId = process.env.HOTJAR_SITE_ID
  ? Number(process.env.HOTJAR_SITE_ID)
  : 3661426;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion, {
  debug: true,
});
ReactDOM.render(<App />, document.getElementById("root"));

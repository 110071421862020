import {
  ContactObj,
  FolderObj,
  ImportContact,
  LabelObj,
  ListAbonnementObj,
} from "../models/apps/Contact";

export const CREATE_NEW_CONTACT = "CREATE_NEW_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const GET_CONTACT_FOLDER_LIST = "GET_CONTACT_FOLDER_LIST";
export const GET_CONTACT_LABEL_LIST = "GET_CONTACT_LABEL_LIST";
export const GET_CONTACT_LIST = "GET_CONTACT_LIST";
export const GET_CONTACT_BY_ID = "GET_CONTACT_BY_ID";
export const UPDATE_CONTACT_STARRED_STATUS = "UPDATE_CONTACT_STARRED_STATUS";
export const UPDATE_CONTACT_DETAIL = "UPDATE_CONTACT_DETAIL";
export const UPDATE_CONTACT_LABEL = "UPDATE_CONTACT_LABEL";
export const TOGGLE_CONTACT_DRAWER = "TOGGLE_CONTACT_DRAWER";
export const SUCCESS_ADD_PASSWORD = "SUCCESS_ADD_PASSWORD";
export const SUCCESS_VERIFY_TOKEN = "SUCCESS_VERIFY_TOKEN";
export const IMPORT_CONTACTS = "IMPORT_CONTACTS";
export const GET_CUISINISTE_LIST = "GET_CUISINISTE_LIST";
export const GET_EVOLUTION_ABONNEMENT = 'GET_EVOLUTION_ABONNEMENT'
export interface GetContactFolderActions {
  type: typeof GET_CONTACT_FOLDER_LIST;
  payload: FolderObj[];
}

export interface GetContactLabelActions {
  type: typeof GET_CONTACT_LABEL_LIST;
  payload: LabelObj;
}

export interface GetContactsActions {
  type: typeof GET_CONTACT_LIST;
  payload: { list: ContactObj[]; total: number };
}
export interface GetContactByIdActions {
  type: typeof GET_CONTACT_BY_ID;
  payload: ContactObj;
}

export interface UpdateContactStarActions {
  type: typeof UPDATE_CONTACT_STARRED_STATUS;
  payload: { data: ContactObj[]; folderName: string };
}

export interface UpdateContactActions {
  type: typeof UPDATE_CONTACT_DETAIL;
  payload: ContactObj;
}

export interface UpdateContactLabelActions {
  type: typeof UPDATE_CONTACT_LABEL;
  payload: { data: ContactObj[]; labelName: string; labelType: number };
}

export interface CreateContactActions {
  type: typeof CREATE_NEW_CONTACT;
  payload: ContactObj;
}

export interface DeleteContactActions {
  type: typeof DELETE_CONTACT;
  payload: { list: ContactObj[]; total: number };
}

export interface ToggleContactDrawerActions {
  type: typeof TOGGLE_CONTACT_DRAWER;
}

export interface AddPasswordActions {
  type: typeof SUCCESS_ADD_PASSWORD;
  payload: boolean;
}

export interface VerifyTokenActions {
  type: typeof SUCCESS_VERIFY_TOKEN;
  payload: string;
}

export interface ImportContactActions {
  type: typeof IMPORT_CONTACTS;
  payload: ImportContact[];
}

export interface GetCuisinisteList {
  type: typeof GET_CUISINISTE_LIST;
  payload: ContactObj[];
}

export interface GetEvolutionAbonnement {
  type: typeof GET_EVOLUTION_ABONNEMENT,
  payload: ListAbonnementObj[]
}

export type ContactActions =
  | GetContactFolderActions
  | GetContactLabelActions
  | GetContactsActions
  | GetContactByIdActions
  | UpdateContactStarActions
  | UpdateContactActions
  | UpdateContactLabelActions
  | CreateContactActions
  | ToggleContactDrawerActions
  | DeleteContactActions
  | AddPasswordActions
  | VerifyTokenActions
  | ImportContactActions
  | GetCuisinisteList
  | GetEvolutionAbonnement;

export const authRole = {
  admin: ["SUDO", "USER"],
  user: ["USER"],
};

export enum RoutePermittedRole {
  Admin = "SUDO",
  User = "USER",
  Responsable = "RESPONSABLE",
  Concepteur_vendeur = "CONCEPTEUR_VENDEUR",
  Manager = "MANAGER",
  GestionMagasins = "GESTIONNAIRE DES MAGASINS",
  GestionGroupes = "GESTIONNAIRE DES GROUPE",
  GestionUtilisateur = "GESTIONNAIRE DES UTILISATEURS",
  GestionReserves = "GESTIONNAIRE DES RESERVES",
}

export const initialUrl = "/dashboard"; // this url will open after login

import {
  ContactActions,
  CREATE_NEW_CONTACT,
  DELETE_CONTACT,
  GET_CONTACT_FOLDER_LIST,
  GET_CONTACT_LABEL_LIST,
  GET_CONTACT_LIST,
  TOGGLE_CONTACT_DRAWER,
  UPDATE_CONTACT_DETAIL,
  UPDATE_CONTACT_LABEL,
  UPDATE_CONTACT_STARRED_STATUS,
  GET_CONTACT_BY_ID,
  SUCCESS_ADD_PASSWORD,
  SUCCESS_VERIFY_TOKEN,
  IMPORT_CONTACTS,
  GET_CUISINISTE_LIST,
  GET_EVOLUTION_ABONNEMENT,
} from "../../types/actions/Contact.actions";
import {
  ContactObj,
  FolderObj,
  ImportContact,
  LabelObj,
  ListAbonnementObj,
} from "../../types/models/apps/Contact";

const initialState: {
  contactList: ContactObj[];
  totalContacts: number;
  contactDrawer: false;
  labelList: LabelObj[];
  folderList: FolderObj[];
  selectedContact: ContactObj | null;
  succesAddPassword: boolean;
  successVerifyToken: string;
  importContactResult: ImportContact[];
  cuisinisteList: ContactObj[];
  evolutionAbonnement: ListAbonnementObj[]
} = {
  contactList: [],
  totalContacts: 0,
  contactDrawer: false,
  labelList: [],
  folderList: [],
  selectedContact: null,
  succesAddPassword: false,
  successVerifyToken: "",
  importContactResult: [],
  cuisinisteList: [],
  evolutionAbonnement: []
};

const ContactApp = (state = initialState, action: ContactActions) => {
  switch (action.type) {
    case GET_CONTACT_LIST:
      return {
        ...state,
        contactList: action.payload.list,
        totalContacts: action.payload.total,
      };

    case GET_CONTACT_FOLDER_LIST:
      return {
        ...state,
        folderList: action.payload,
      };

    case TOGGLE_CONTACT_DRAWER:
      return {
        ...state,
        contactDrawer: !state.contactDrawer,
      };

    case GET_CONTACT_LABEL_LIST:
      return {
        ...state,
        labelList: action.payload,
      };

    case CREATE_NEW_CONTACT:
      return {
        ...state,
        contactList: [action.payload, ...state.contactList],
        totalContacts: state.totalContacts + 1,
      };

    case DELETE_CONTACT: {
      const ids = action.payload.list.map((value) => value.id!);
      return {
        ...state,
        contactList: state.contactList.filter(
          (contact) => !ids.includes(contact.id!)
        ),
        totalContacts: state.totalContacts - action.payload.total,
      };
    }

    case UPDATE_CONTACT_LABEL: {
      let contactIds = action.payload.data.map((contact) => contact.id);
      const updatedList = state.contactList.map((contact) => {
        if (contactIds.includes(contact.id)) {
          return action.payload.data.find(
            (selectedContact) => selectedContact.id === contact.id
          );
        } else {
          return contact;
        }
      });
      const filteredList = updatedList;
      const total = filteredList.length;
      return {
        ...state,
        contactList: filteredList,
        totalContacts: total,
      };
    }

    case UPDATE_CONTACT_STARRED_STATUS: {
      let contactIds = action.payload.data.map((contact) => contact.id);
      const updatedList = state.contactList.map((contact) => {
        if (contactIds.includes(contact.id)) {
          return action.payload.data.find(
            (selectedContact) => selectedContact.id === contact.id
          );
        } else {
          return contact;
        }
      });
      const filteredList = updatedList;
      const total =
        action.payload.folderName === "starred"
          ? state.totalContacts - action.payload.data.length
          : state.totalContacts;
      return {
        ...state,
        contactList: filteredList,
        totalContacts: total,
      };
    }

    case UPDATE_CONTACT_DETAIL:
      return {
        ...state,
        selectedContact: action.payload,
        contactList: state.contactList.map((contact) =>
          contact.id === action.payload.id ? action.payload : contact
        ),
      };
    case GET_CONTACT_BY_ID:
      return {
        ...state,
        selectedContact: action.payload,
      };
    case SUCCESS_ADD_PASSWORD:
      return {
        ...state,
        successAddPassword: true,
      };
    case SUCCESS_VERIFY_TOKEN:
      return {
        ...state,
        successVerifyToken: action.payload,
      };
    case IMPORT_CONTACTS: {
      return {
        ...state,
        importContactResult: action.payload,
      };
    }

    case GET_CUISINISTE_LIST:
      return {
        ...state,
        cuisinisteList: action.payload,
      };

    case GET_EVOLUTION_ABONNEMENT: {
      return {
        ...state,
        evolutionAbonnement: action.payload
      }
    }

    default:
      return state;
  }
};
export default ContactApp;

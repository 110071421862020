export interface AuthUser {
  id?: number;
  uid?: string;
  displayName?: string;
  email?: string;
  photoURL?: string;
  token?: string;
  role?: string[] | string;
}

export enum userRole {
  SUDO = "SUDO",
  USER = "USER",
  RESPONSABLE = "RESPONSABLE",
  MANAGER = "MANAGER",
  CONCEPTEUR_VENDEUR = "CONCEPTEUR_VENDEUR",
}

import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import jwtAxios, { setAuthToken } from "./index";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../../redux/actions";
import { ContactObj } from "../../../../types/models/apps/Contact";
import { onGetTemplateList } from "../../../../redux/actions/RapportTemplate";
import { getMessageErreur } from "shared/functions";
import { useHistory, useLocation } from "react-router-dom";
import { WebSocketNotifications, WebSocketNotificationsTable } from "shared/socket/socket";

interface JWTAuthContextProps {
  user: ContactObj | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
  notifyUserInfoChange: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {},
  notifyUserInfoChange: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const [userInfoChange, setUserInfoChange] = useState<boolean>(false);

  const notifyUserInfoChange = () => {
    setUserInfoChange((value) => !value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("AuthSignout", async () => {
      await logout();
    });
    return () => {
      document.addEventListener("AuthSignout", async () => {
        await logout();
      });
    };
  });

  // For websocket
  React.useEffect(() => {
    document.addEventListener("notification", onNotification);

    return () => {
      document.addEventListener("notification", onNotification);
    };
  });

  const onNotification = (evt: Event) => {
    const customEvent = evt as CustomEvent<WebSocketNotifications<any>>;
    if (!customEvent.detail.table) return;
    if (customEvent.detail.table === WebSocketNotificationsTable.User)
      notifyUserInfoChange();
  };

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get("/users/me")
        .then(({ data }) =>
          setJWTAuthData({
            user: data,
            isLoading: false,
            isAuthenticated: true,
          })
        )
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          })
        );
    };

    getAuthUser();
  }, [userInfoChange]);

  const signInUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    try {
      const { data } = await jwtAxios.post("auth/login", { email, password });
      localStorage.setItem("token", data.accessToken);
      setAuthToken(data.accessToken);
      console.log(data.accessToken);
      const res = await jwtAxios.get("/users/me");
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(onGetTemplateList());
      dispatch(fetchSuccess());
    } catch (error: unknown) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError(getMessageErreur(error)));
    }
  };

  const signUpUser = async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    try {
      const { data } = await jwtAxios.post("users", { name, email, password });
      localStorage.setItem("token", data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get("/auth");
      setJWTAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch(fetchError("Something went wrong"));
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          notifyUserInfoChange,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

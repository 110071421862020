import React, { useState } from "react";
import { ReactNode, useContext, createContext } from "react";

export type TourContextType = {
  tourList: string[];
};

export type TourActionContextType = {
  setTourData: (data: string) => void;
};

const ContextState: TourContextType = {
  tourList: [],
};

const TourContext = createContext<TourContextType>(ContextState);

const TourActionsContext = createContext<TourActionContextType>({
  setTourData: (data: string) => {},
});

export const useTourContext = () => useContext(TourContext);

export const useTourActionsContext = () => useContext(TourActionsContext);

type Props = {
  children: ReactNode;
};

export const TourContextProvider = ({ children }: Props) => {
  const [list, setList] = useState<string[]>([]);
  const setTourListData = (value: string) => {
    if (!list.find((l) => l === value)) {
      setList([value, ...list]);
      localStorage.setItem("list", JSON.stringify([value, ...list]));
    }
  };

  React.useEffect(() => {
    const tourlist = localStorage.getItem("list");
    if (tourlist) {
      const tourlistParse = JSON.parse(tourlist) as string[];
      setList(tourlistParse);
    }
  }, []);

  return (
    <TourContext.Provider value={{ tourList: list }}>
      <TourActionsContext.Provider value={{ setTourData: setTourListData }}>
        {children}
      </TourActionsContext.Provider>
    </TourContext.Provider>
  );
};

export default TourContextProvider;

import React from "react";
import { RoutePermittedRole } from "../../shared/constants/AppConst";

export const abonnementsRouteConfig = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Responsable,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionReserves,
      RoutePermittedRole.User,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.Responsable,
    ],
    path: ["/abonnements"],
    component: React.lazy(() => import(".")),
  },
];

import { Contremarque } from "types/models/apps/Contremarque";
import {
  CREATE_NEW_CONTREMARQUE,
  DELETE_CONTREMARQUE,
  GET_CONTREMARQUE_LIST,
  ContremarqueActions,
  UPDATE_CONTREMARQUE_DETAIL,
  UNARCHIVE_CONTREMARQUE,
} from "../../types/actions/Contremarque.action";

const initialState: {
  contremarqueList: Contremarque[];
  totalContremarque: number;
  selectedContremarque: Contremarque | null;
} = {
  contremarqueList: [],
  totalContremarque: 0,
  selectedContremarque: null,
};

const ContremarqueApp = (state = initialState, action: ContremarqueActions) => {
  switch (action.type) {
    case GET_CONTREMARQUE_LIST:
      return {
        ...state,
        contremarqueList: action.payload.list,
        totalContremarque: action.payload.total,
      };

    case CREATE_NEW_CONTREMARQUE:
      return {
        ...state,
        contremarqueList: [action.payload, ...state.contremarqueList],
        totalContremarque: state.totalContremarque + 1,
      };

    case DELETE_CONTREMARQUE: {
      return {
        ...state,
        contremarqueList: state.contremarqueList.filter(
          (contremarque) => contremarque.id !== state.selectedContremarque?.id
        ),
        totalContremarque: state.totalContremarque - action.payload.total,
      };
    }

    case UNARCHIVE_CONTREMARQUE: {
      return {
        ...state,
        contremarqueList: state.contremarqueList.filter(
          (contremarque) => contremarque.id !== action.payload.list.id
        ),
        totalContremarque: state.totalContremarque + 1,
      };
    }

    case UPDATE_CONTREMARQUE_DETAIL:
      return {
        ...state,
        selectedContremarque: action.payload,
        contremarqueList: state.contremarqueList.map((contremarque) =>
          contremarque.id === action.payload.id ? action.payload : contremarque
        ),
      };

    default:
      return state;
  }
};
export default ContremarqueApp;

import { CREATE_INTENT, StripeActions } from "types/actions/Stripe.actions";

const initialState: {
  clientSecret: string | undefined;
} = {
  clientSecret: undefined,
};

const StripeApp = (state = initialState, action: StripeActions) => {
  const { payload, type } = action;
  switch (type) {
    case CREATE_INTENT: {
      return {
        clientSecret: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default StripeApp;

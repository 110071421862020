import jwtAxios from "@crema/services/auth/jwt-auth";
import { appIntl } from "@crema/utility/helper/Utils";
import { Dispatch } from "redux";
import { AppActions } from "../../types";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import {
  GET_GRILLE_ACTION,
  GET_ONE_GRILLE_ACTION,
  SAVE_GRILLE_ACTION,
  UPDATE_GRILLE_ACTION,
} from "types/actions/Grille.action";
import { GrilleDto } from "types/models/apps/Grille";

export const onGetAllAbonnement = (params?: {}) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`grilles`, { params })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_GRILLE_ACTION,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetGrille = (id: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`grilles/${id}`)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_ONE_GRILLE_ACTION,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onCreateAbonnement = (data: GrilleDto) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post("grilles/", data)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: SAVE_GRILLE_ACTION,
            payload: data.data,
          });
          dispatch(showMessage("Abonnemenr créé avec success. " as string));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onUpdateAbonnement = (id: number, data: GrilleDto) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch(`grilles`, data)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: UPDATE_GRILLE_ACTION,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onArchiveAbonnement = (id: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch(`grilles/${id}/archiver`)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          console.log("dattaaa", data.data);
          dispatch({
            type: UPDATE_GRILLE_ACTION,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDesarchiveAbonnement = (id: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch(`grilles/${id}/desarchiver`)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          console.log("dattaaa", data.data);
          dispatch({
            type: UPDATE_GRILLE_ACTION,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDesarchiveManyAbonnement = (id: number[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch(`grilles/desarchiveMany`, { id })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          console.log("dattaaa", data.data);
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onArchiveManyAbonnement = (id: number[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch(`grilles/archiveMany`, { id })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          console.log("dattaaa", data.data);
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const GET_RAPPORT_TEMPLATE_LIST = "GET_RAPPORT_TEMPLATE_LIST";

export interface RapportTemplateObj {
  id: number;
  html: string;
  nomRapport: string;
}

export interface GetRapportTemplateListActions {
  type: typeof GET_RAPPORT_TEMPLATE_LIST;
  payload: {
    list: RapportTemplateObj[];
    total: number;
  };
}

export type RapportTemplateActions =
  | GetRapportTemplateListActions;
import { appIntl } from "@crema/utility/helper/Utils";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import jwtAxios from "../../@crema/services/auth/jwt-auth";
import folderList from "../../@crema/services/db/apps/magasin/folderList";
import { AppActions } from "../../types";
import {
  CREATE_NEW_CONTREMARQUE,
  DELETE_CONTREMARQUE,
  GET_CONTREMARQUE_LIST,
  UPDATE_CONTREMARQUE_DETAIL,
} from "../../types/actions/Contremarque.action";
import { AppState } from "../store";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import { Contremarque } from "types/models/apps/Contremarque";

export const onCreateContremarque = (reserve: Contremarque) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post("/contremarques", reserve)
      .then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: CREATE_NEW_CONTREMARQUE, payload: data.data });
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetContremarqueList = (params?: {}) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/contremarques/", { params })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CONTREMARQUE_LIST,
            payload: { list: data.data.contremarques, total: data.data.count },
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onUpdateContremarqueDetail = (contremarque: Contremarque) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/contremarques/" + contremarque.id, contremarque)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_CONTREMARQUE_DETAIL, payload: data.data });
          // dispatch(showMessage(messages["message.objectUpdated"] as string));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteContremarque = (contremarqueId: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/contremarques/" + contremarqueId + "/archiver")
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: DELETE_CONTREMARQUE,
            payload: { list: data.data, total: data.data.length },
          });
          dispatch(showMessage("Contremarque supprimée avec succès"));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onUnarchiveContremarque = (contremarqueId: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/contremarques/" + contremarqueId + "/desarchiver")
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: DELETE_CONTREMARQUE,
            payload: { list: data.data, total: data.data.length },
          });
          dispatch(showMessage("Contremarque désarchivée avec succès"));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onUnarchiveContremarques = (contremarqueId: number[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/contremarques/desarchiverplusieurs", { ids: contremarqueId })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch(showMessage("Contremarques désarchivée avec succès"));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteContremarques = (contremarqueId: number[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/contremarques/archiverplusieurs", { ids: contremarqueId })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch(showMessage("Contremarques archivée avec succès"));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

import { ReserveObj } from "../models/apps/Reserve";
import { SEND_RAPPORT_LEVEE_RESERVE_BY_MAIL } from "./Todo.action";

export const GET_RESERVE_LIST = "GET_RESERVE_LIST";
export const UPDATE_RESERVE_DETAIL = "UPDATE_RESERVE_DETAIL";
export const CREATE_NEW_RESERVE = "CREATE_NEW_RESERVE";
export const DELETE_RESERVE = "DELETE_RESERVE";
export const LEVEE_RESERVE = "LEVEE_RESERVE";



export interface GetReservesActions {
  type: typeof GET_RESERVE_LIST;
  payload: { list: ReserveObj[]; total: number };
}
export interface UpdateReserveActions {
  type: typeof UPDATE_RESERVE_DETAIL;
  payload: ReserveObj;
}

export interface CreateReserveActions {
  type: typeof CREATE_NEW_RESERVE;
  payload: ReserveObj;
}

export interface DeleteReserveActions {
  type: typeof DELETE_RESERVE;
  payload: { list: ReserveObj[]; total: number };
}
export interface SendRapportLeveeReserveByMailAction {
  type: typeof SEND_RAPPORT_LEVEE_RESERVE_BY_MAIL;
}

export interface LeveeReserveActions  {
  type: typeof LEVEE_RESERVE;
  payload: { list: ReserveObj[];}
}

export type ReserveActions =
  | GetReservesActions
  | UpdateReserveActions
  | CreateReserveActions
  | DeleteReserveActions
  | SendRapportLeveeReserveByMailAction
  | LeveeReserveActions;

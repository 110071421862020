import React from "react";
import { RoutePermittedRole } from "../../shared/constants/AppConst";

export const templateRouteConfig = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Responsable,
      RoutePermittedRole.GestionMagasins,
      // RoutePermittedRole.GestionUtilisateur,
      RoutePermittedRole.GestionGroupes,
    ],
    path: ["/template/:type", "/template", "/template/:id/:type"],
    component: React.lazy(() => import("./index")),
  },
];

import {
  CREATE_GROUP_MEMBERSHIP,
  DELETE_GROUP_MEMBERSHIP,
  GET_GROUP_MEMBERSHIPS,
  MembershipActions,
  UPDATE_GROUP_MEMBERSHIP,
} from "types/actions/Membership";
import { MembershipObj } from "types/models/apps/Membership";

const initialState: {
  membershipList: MembershipObj[];
  totalMembership: number;
  selectedMembership: MembershipObj | null;
} = {
  membershipList: [],
  totalMembership: 0,
  selectedMembership: null,
};

const MembershipApp = (state = initialState, action: MembershipActions) => {
  switch (action.type) {
    case CREATE_GROUP_MEMBERSHIP:
      return {
        ...state,
        membershipList: [action.payload, ...state.membershipList],
        totalMembership: state.totalMembership + 1,
      };

    case UPDATE_GROUP_MEMBERSHIP:
      return {
        ...state,
        selectedMember: action.payload,
        membershipList: state.membershipList.map((member) =>
          member.user.id === action.payload.user.id ? action.payload : member
        ),
      };

    case DELETE_GROUP_MEMBERSHIP:
      return {
        ...state,
        membershipList: state.membershipList.filter(
          (member) => member.id !== action.payload.id
        ),
      };

    case GET_GROUP_MEMBERSHIPS:
      return {
        ...state,
        membershipList: action.payload.list.map((data) => {
          return {
            id: data.id,
            user: data.user,
            role: data.role,
            groupId: data.groupId,
          };
        }),
        totalMembership: action.payload.length,
      };

    default:
      return state;
  }
};
export default MembershipApp;

import { authRouteConfig } from "./auth";
import Error403 from "./errorPages/Error403";
import { errorPagesConfigs } from "./errorPages";
import {
  adminPagesConfigs,
  grillePagesConfigs,
  groupsPagesConfigs,
  magasinsPagesConfigs,
} from "./admin";
import { posesPagesConfigs } from "./pose";
import { templateRouteConfig } from "./template/templateRouteConfig";
import { reserveRouteConfig } from "./Reserve/ReserveRouteConfig";
import { contremarqueRouteConfig } from "./contremarques/ContremarqueRouteConfig";
import { metricRouteConfig } from "./metrics/metricRouteConfig";
import { welcomeRoute } from "./welcome/welcomeRoute";
import { abonnementRouteConfig } from "./abonnement/abonnementRouteConfig";
import { abonnementsRouteConfig } from "./abonnements/abonnementsRouerConfig";
import { statusRoute } from "./statusPayment/route";

const authorizedStructure = {
  fallbackPath: "/home",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...adminPagesConfigs,
    ...reserveRouteConfig,
    ...posesPagesConfigs,
    ...groupsPagesConfigs,
    ...magasinsPagesConfigs,
    ...templateRouteConfig,
    ...contremarqueRouteConfig,
    ...metricRouteConfig,
    ...abonnementRouteConfig,
    ...grillePagesConfigs,
    ...abonnementsRouteConfig,
    ...statusRoute,
  ],
};

const unAuthorizedStructure = {
  routes: [...authRouteConfig],
};

const anonymousStructure = {
  routes: [...errorPagesConfigs, ...welcomeRoute],
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };

export function useLocalStorage<T>(
  key: string,
  defaultValue: T
): [value: T, updateValue: (newValue?: T) => void] {
  const updateValue = (newValue?: T) => {
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  const json = localStorage.getItem(key);
  const value = json ? (JSON.parse(json) as T) : defaultValue;

  if (!json) {
    updateValue(defaultValue);
  }

  return [value, updateValue];
}

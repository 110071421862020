import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePermittedRole } from "../../shared/constants/AppConst";

export const posesPagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.User,
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionUtilisateur,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.Responsable
    ],
    path: [
      "/pose/rapports/rapport/:id",
      "/pose/rapports/:label/:name/:id?",
      "/pose/rapports/:folder/",
    ],
    component: React.lazy(() => import("./RapportIntervention")),
  },
  {
    permittedRole: [
      RoutePermittedRole.User,
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionUtilisateur,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.Responsable
    ],
    path: "/pose/rapports",
    component: () => {
      return <Redirect to="/pose/rapports/all" />;
    },
  },
];

import { ReactNode } from "react";
import { BiFile, BiGroup, BiStore, BiUser } from "react-icons/bi";
import { RoutePermittedRole } from "../shared/constants/AppConst";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BookmarkAddOutlined from "@mui/icons-material/BookmarkAddOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole[];
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

const routesConfig: RouterConfigData[] = [
  {
    id: "admin",
    title: "Administration des Groupes",
    messageId: "Administration des Groupes",
    type: "item",
    icon: <BiGroup />,
    url: "/groups",
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionUtilisateur,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.Responsable,
    ],
  },
  {
    id: "admin",
    title: "Administration des Utilisateurs",
    messageId: "Administration des Utilisateurs",
    type: "item",
    icon: <BiUser />,
    url: "/admin/contacts",
    permittedRole: [RoutePermittedRole.Admin],
  },
  {
    id: "grille",
    title: "Administration des grilles",
    messageId: "Grille tarifaire",
    type: "item",
    icon: <BiUser />,
    url: "/grilles",
    permittedRole: [RoutePermittedRole.Admin], 
  },
  {
    id: "admin",
    title: "Administration des Magasins",
    messageId: "Administration des Magasins",
    type: "item",
    icon: <BiStore />,
    url: "/magasins",
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.Responsable,
    ],
  },
  {
    id: "pose",
    title: "Rapports d'interventions",
    messageId: "Rapports d'interventions",
    type: "item",
    icon: <BiFile />,
    url: "/pose/rapports",
    permittedRole: [
      RoutePermittedRole.User,
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.Responsable,
    ],
  },
  {
    id: "contremarque",
    title: "Contremarques",
    messageId: "Contremarques",
    type: "item",
    icon: <InfoOutlined />,
    url: "/contremarques",
    permittedRole: [
      RoutePermittedRole.User,
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.Responsable,
    ],
  },
  {
    id: "reserve",
    title: "Reserves",
    messageId: "Reserves",
    type: "item",
    icon: <BookmarkAddOutlined />,
    url: "/reserves/rapports",
    permittedRole: [
      RoutePermittedRole.User,
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionReserves,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.Responsable,
    ],
  },
  {
    id: "template",
    title: "Template",
    messageId: "Modèles de document",
    type: "item",
    url: "/template",
    icon: <BiFile />,
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Responsable,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
    ],
  },
  {
    id: "metrics",
    title: "Dashboard",
    messageId: "Dashboard",
    type: "item",
    url: "/dashboard",
    icon: <DashboardIcon />,
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Responsable,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.User,
    ],
  },
];
export default routesConfig;

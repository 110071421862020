import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePermittedRole } from "../../shared/constants/AppConst";

export const adminPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ["/admin/contacts/:name", "/admin/contacts/label/:name"],
    component: React.lazy(() => import("./Contact")),
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: "/admin/contacts",
    component: () => <Redirect to="/admin/contacts/all" />,
  },
];

export const groupsPagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Responsable,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionMagasins,
    ],
    path: ["/groups/:name", "/groups/label/:name"],
    component: React.lazy(() => import("./Group")),
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.Responsable
    ],
    path: "/groups",
    component: () => <Redirect to="/groups/all" />,
  },
];

export const magasinsPagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Responsable,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
    ],
    path: ["/magasins/:name", "/magasins/label/:name"],
    component: React.lazy(() => import("./Magasin")),
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.Responsable
    ],
    path: "/magasins",
    component: () => <Redirect to="/magasins/all" />,
  },
];

export const grillePagesConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
    ],
    path: ["/grilles/:name", "/grilles/label/:name"],
    component: React.lazy(() => import("./grille")),
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
    ],
    path: "/grilles",
    component: () => <Redirect to="/grilles/all" />,
  },
];

import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const metricRouteConfig = [
  {
    permittedRole: [
      RoutePermittedRole.User,
      RoutePermittedRole.Admin,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionUtilisateur,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager,
      RoutePermittedRole.Responsable,
    ],
    path: ["/dashboard/"],
    component: React.lazy(() => import("./index")),
  },
];

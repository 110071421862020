import {
  authRole,
  RoutePermittedRole,
} from "../../../shared/constants/AppConst";
import { ContactObj } from "../../../types/models/apps/Contact";

export const getUserFromAuth0 = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.sub,
      displayName: user.name,
      email: user.email,
      photoURL: user.picture,
      role: authRole.user,
    };
  return user;
};

export const getUserFromFirebase = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.uid,
      displayName: user.displayName ? user.displayName : "Crema User",
      email: user.email,
      photoURL: user.photoURL ? user.photoURL : "/assets/images/avatar/A11.jpg",
      role: authRole.user,
    };
  return user;
};
export const getUserFromAWS = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: user.username,
      displayName: user.attributes.name ? user.attributes.name : "Crema User",
      email: user.attributes.email,
      photoURL: user.photoURL,
      role: authRole.user,
    };
  return user;
};

export const getUserFromJwtAuth = (user: ContactObj | null | undefined) => {
  if (user) {
    return {
      id: user.id,
      uid: user.id?.toString(),
      displayName: user.surname + " " + user.name,
      email: user.email,
      photoURL: user.profilePictureUrl,
      role: user.role as string,
      memberships: user.memberships,
      displayRole: user.role,
      abonnement: user.abonnement,
    };
  }
  return user;
};

function getFrontRoleFromUser(contact: ContactObj) {
  if (contact.role === "SUDO") return RoutePermittedRole.Admin;
  if (
    contact.memberships?.map((m) => m.role).includes("ADMIN") &&
    contact.role === "USER"
  )
    return RoutePermittedRole.GestionGroupes;
  if (contact.memberships?.map((m) => m.role).includes("MEMBER"))
    return RoutePermittedRole.User;
  if (contact.memberships?.map((m) => m.role).includes("OWNER"))
    return RoutePermittedRole.GestionMagasins;
}

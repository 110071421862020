import axios from "axios";

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:4500/v1", // YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === "Token is not valid") {
      console.log("Need to logout user");
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  }
);
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    const unauthorized = err?.response?.status === 401;
    if(unauthorized){
      console.log('Api.interceptors: Unauthorized => Logout user');
      document.dispatchEvent(new CustomEvent('AuthSignout'));
    }
    return Promise.reject(err);
  }
)
export const setAuthToken = (token?: string) => {
  if (token) {
    jwtAxios.defaults.headers['Authorization'] = `Bearer ${token}`;
    localStorage.setItem("token", token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem("token");
  }
};

export default jwtAxios;

export const dataInfo: {
  id: string;
  content: string[];
  style?: React.CSSProperties;
  contentHTML: string[];
}[] = [
  {
    id: "title",
    content: [
      "",
      "",
      ` <h1 style="text-align:right">
    <span
      style="
        color: rgb(206, 212, 217);
        font-family: 'times new roman', times, serif;
      "
      >RAPPORT D'INTERVENTION</span
    >
  </h1>`,
    ],
    contentHTML: [
      "",
      "",
      `<h1>
      <span style="
          color: rgb(206, 212, 217);
          font-family: 'times new roman', times, serif;
        ">RAPPORT D'INTERVENTION</span>
    </h1>`,
    ],
  },

  {
    id: "blogReference",
    style: {
      backgroundColor: "#ced4d9",
      height: " 80.1876px",
    },
    content: [
      `<td style="border-width: 0px">
    <span style="font-family: 'times new roman', times, serif"
      >&nbsp;Reference dossier image &nbsp;</span
    >
  </td>`,
      `
      <table>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
  <span style="font-family: 'times new roman', times, serif"
    >R&eacute;f&eacute;rence dossier</span
  >
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
      <span style="font-family: 'times new roman', times, serif"
        >&nbsp; Numero commande &nbsp;</span
      >
    </td>
      </tr>
      </table>
      
      `,
      `
      <table>
      <tr><td style="border-width: 0px; height: 19.5938px">
<span style="font-family: 'times new roman', times, serif"
  >Date de saisie</span
>
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
                <span style="font-family: 'times new roman', times, serif"
                  >&nbsp;date de saisie&nbsp;</span
                >
              </td>
      </tr>
      </table>
      `,
    ],
    contentHTML: [
      `<span style="font-family: 'times new roman', times, serif">&nbsp;${"${reference_dossier_img}"}&nbsp;</span>`,
      `
      <table>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
  <span style="font-family: 'times new roman', times, serif"
    >R&eacute;f&eacute;rence dossier</span
  >
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
      <span style="font-family: 'times new roman', times, serif"
        >&nbsp;${"${numero_de_commande}"};</span
      >
    </td>
      </tr>
      </table>
      
      `,
      `
      <table>
      <tr><td style="border-width: 0px; height: 19.5938px">
<span style="font-family: 'times new roman', times, serif"
  >Date de saisie</span
>
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
                <span style="font-family: 'times new roman', times, serif"
                  >&nbsp;${"${date_de_saisie}"};</span
                >
              </td>
      </tr>
      </table>
      `,
    ],
  },
  {
    id: "clientInfo",
    content: [
      `
    <table style="width:100%" >
      <tr>
      <td style="height: 19.5938px; border-width: 0px; width:30%">
        <span style="font-family: 'times new roman', times, serif"
          >Nom client :</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; John &nbsp;</span
        >
      </td>
      </tr>
      <tr>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Pr&eacute;nom :</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; Doe &nbsp;</span
        >
      </td>
      </tr>
      <tr>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >T&eacute;l&eacute;phone :</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; +3355688487 &nbsp;</span
        >
      </td>
      </tr>
    </table>
    `,
      `
    <table style="width:100%">
    <tr>
    <td style="height: 19.5938px; border-width: 0px; width:30%">
    <span style="font-family: 'times new roman', times, serif"
      >Adresse&nbsp;</span
    >
  </td>
  <td style="height: 19.5938px; border-width: 0px">
    <span style="font-family: 'times new roman', times, serif"
      >&nbsp; Paris &nbsp;</span
    >
  </td>
    </tr>
    <tr>
    <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Code postal :</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; 11112 &nbsp;</span
        >
      </td>
    </tr>
    <tr>
    <td style="height: 19.5938px; border-width: 0px">
    <span style="font-family: 'times new roman', times, serif"
      >Ville :</span
    >
  </td>
  <td style="height: 19.5938px; border-width: 0px">
    <span style="font-family: 'times new roman', times, serif"
      >&nbsp; Paris &nbsp;</span
    >
  </td>
    </tr>
    </table>
    `,
    ],
    contentHTML: [
      `<table style="width:100%" >
      <tr>
      <td style="height: 19.5938px; border-width: 0px; width:30%">
        <span style="font-family: 'times new roman', times, serif"
          >Nom client :</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;  ${"${nom_client}"} &nbsp;</span
        >
      </td>
      </tr>
      <tr>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Pr&eacute;nom :</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;  ${"${prenom_client}"}&nbsp;</span
        >
      </td>
      </tr>
      <tr>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >T&eacute;l&eacute;phone :</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;  ${"${telephone}"} &nbsp;</span
        >
      </td>
      </tr>
    </table>`,
      `<table style="width:100%">
      <tr>
      <td style="height: 19.5938px; border-width: 0px; width:30%">
      <span style="font-family: 'times new roman', times, serif"
        >Adresse&nbsp;</span
      >
    </td>
    <td style="height: 19.5938px; border-width: 0px">
      <span style="font-family: 'times new roman', times, serif"
        >&nbsp; ${"${addresse}"} &nbsp;</span
      >
    </td>
      </tr>
      <tr>
      <td style="height: 19.5938px; border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >Code postal :</span
          >
        </td>
        <td style="height: 19.5938px; border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&nbsp; ${"${code_postal}"} &nbsp;</span
          >
        </td>
      </tr>
      <tr>
      <td style="height: 19.5938px; border-width: 0px">
      <span style="font-family: 'times new roman', times, serif"
        >Ville :</span
      >
    </td>
    <td style="height: 19.5938px; border-width: 0px">
      <span style="font-family: 'times new roman', times, serif"
        >&nbsp; ${"${ville}"} &nbsp;</span
      >
    </td>
      </tr>
      </table>`,
    ],
  },
  {
    id: "intervention",
    style: {
      backgroundColor: "#ced4d9",
      height: " 80.1876px",
    },
    content: [
      `<td style="border-width: 0px">
    <span style="font-family: 'times new roman', times, serif"
      >&nbsp; intervenant image &nbsp;</span
    >
  </td>`,
      `
      <table>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
  <span style="font-family: 'times new roman', times, serif"
    >Intervenant</span
  >
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
      <span style="font-family: 'times new roman', times, serif"
        >&nbsp;Nom intervenant &nbsp;</span
      >
    </td>
      </tr>
      </table>
      
      `,
      `
      <table>
      <tr><td style="border-width: 0px; height: 19.5938px">
<span style="font-family: 'times new roman', times, serif"
  >Date de l&rsquo;intervention</span
>
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
                <span style="font-family: 'times new roman', times, serif"
                  >&nbsp;11/11/11&nbsp;</span
                >
              </td>
      </tr>
      </table>
      `,
    ],
    contentHTML: [
      ` <span style="font-family: 'times new roman', times, serif">&nbsp;${"${intervenant_img}"}&nbsp;</span>`,
      `<table>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
  <span style="font-family: 'times new roman', times, serif"
    >Intervenant</span
  >
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
      <span style="font-family: 'times new roman', times, serif"
        >&nbsp; ${"${nom_intervenant}"} &nbsp;</span
      >
    </td>
      </tr>
      </table>`,
      `<table>
      <tr><td style="border-width: 0px; height: 19.5938px">
<span style="font-family: 'times new roman', times, serif"
  >Date de l&rsquo;intervention</span
>
</td>
      </tr>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
                <span style="font-family: 'times new roman', times, serif"
                  >&nbsp; ${"${date_de_saisie}"}&nbsp;</span
                >
              </td>
      </tr>
      </table>`,
    ],
  },
  {
    id: "informationIntervenant",
    content: [
      `
    <table
  style="border-collapse: collapse; width: 100%; border-width: 0px"
  border="1"
>
  <colgroup>
    <col style="width: 47.8022%" />
    <col style="width: 52.1978%" />
  </colgroup>
  <tbody>
    <tr>
      <td style="border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Type d&rsquo;intervention&nbsp;</span
        >
      </td>
      <td style="border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2713;Salle de bain &nbsp; &#x2610;Travaux &nbsp; &#x2610;SAV/Retour &nbsp; &#x2610;Cuisine;</span
        >
      </td>
    </tr>
    <tr>
      <td style="border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Magasin</span
        >
      </td>
      <td style="border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;magasin&nbsp;</span
        >
      </td>
    </tr>
    <tr>
      <td style="border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Num&eacute;ro de commande :</span
        >
      </td>
      <td style="border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;numero_de_commande&nbsp;</span
        >
      </td>
    </tr>
  </tbody>
</table>
    `,
    ],
    contentHTML: [
      `<table
      style="border-collapse: collapse; width: 100%; border-width: 0px"
      border="1"
    >
      <colgroup>
        <col style="width: 47.8022%" />
        <col style="width: 52.1978%" />
      </colgroup>
      <tbody>
        <tr>
          <td style="border-width: 0px">
            <span style="font-family: 'times new roman', times, serif"
              >Type d&rsquo;intervention&nbsp;</span
            >
          </td>
          <td style="border-width: 0px">
            <span style="font-family: 'times new roman', times, serif"
              >&nbsp; ${"${type_intervenantion}"}</span
            >
          </td>
        </tr>
        <tr>
          <td style="border-width: 0px">
            <span style="font-family: 'times new roman', times, serif"
              >Magasin</span
            >
          </td>
          <td style="border-width: 0px">
            <span style="font-family: 'times new roman', times, serif"
              >&nbsp; ${"${magasin}"}&nbsp;</span
            >
          </td>
        </tr>
        <tr>
          <td style="border-width: 0px">
            <span style="font-family: 'times new roman', times, serif"
              >Num&eacute;ro de commande :</span
            >
          </td>
          <td style="border-width: 0px">
            <span style="font-family: 'times new roman', times, serif"
              >&nbsp; ${"${numero_de_commande}"}&nbsp;</span
            >
          </td>
        </tr>
      </tbody>
    </table>`,
    ],
  },
  {
    id: "conformite",
    style: {
      backgroundColor: "#ced4d9",
      height: " 80.1876px",
    },
    content: [
      `<td style="border-width: 0px">
    <span style="font-family: 'times new roman', times, serif"
      >&nbsp;conformite image image &nbsp;</span
    >
  </td>`,
      `
      <table>
      <tr>
      <td style="border-width: 0px; height: 19.5938px">
  <span style="font-family: 'times new roman', times, serif"
    >Conformité</span
  >
</td>
      </tr>
      </table>
      
      `,
      `
      `,
    ],
    contentHTML: [
      `<span style="font-family: 'times new roman', times, serif"
      >&nbsp; ${"${conformite_img}"} &nbsp;</span
    >`,
      `<span style="font-family: 'times new roman', times, serif"
    >Conformit&eacute;</span
  >`,
      ``,
    ],
  },
  {
    id: "infoGlobale",
    contentHTML: [
      `<table
    style="border-collapse: collapse; width: 100%; border-width: 0px"
    border="1"
  >
    <colgroup>
      <col style="width: 49.9086%" />
      <col style="width: 49.9086%" />
    </colgroup>
    <tbody>
      <tr>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&Ecirc;tes-vous satisfait de la prestation de notre intervenant
            ?</span
          ><span style="font-family: 'times new roman', times, serif"
          >&nbsp;  ${"${satisfaction}"} &nbsp;</span
        >
        </td>
        <td style="border-width: 0px">
          
        </td>
      </tr>
      <tr>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >L&rsquo;intervention est-elle termin&eacute;e ? (Hors &eacute;ventuel
            SAV)&nbsp;</span
          > <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${intervention_termine}"}&nbsp;</span
        >
        </td>
        <td style="border-width: 0px">
         
        </td>
      </tr>
      <tr>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&Ecirc;tes-vous satisfait de la propret&eacute; en fin de travaux
            ?&nbsp;</span
          > <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${satisfaction_travaux}"} &nbsp;</span
        >
        </td>
        <td style="border-width: 0px">
         
        </td>
      </tr>
    </tbody>
  </table>
  <table
  style="
    border-collapse: collapse;
    width: 100%;
    height: 156.75px;
    border-width: 0px;
  "
  border="1"
>
  <colgroup>
    <col style="width: 24.5283%" />
    <col style="width: 28.3019%" />
    <col style="width: 16.0377%" />
    <col style="width: 30.9434%" />
  </colgroup>
  <tbody>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Plan de travail&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${plan_de_travail}"}</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Plinthes</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${plinthes}"}</span
        >
      </td>
    </tr>
    <tr style="height: 39.1875px">
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Portes et poign&eacute;es&nbsp;</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${portes_et_poignees}"}</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&Eacute;lectricit&eacute;</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${electricite}"}</span
        >
      </td>
    </tr>
    <tr style="height: 39.1875px">
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&Eacute;vier, &eacute;lectricit&eacute; et accessoires&nbsp;</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${electricite}"}</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Plomberie</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${plomberie}"}</span
        >
      </td>
    </tr>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Cr&eacute;dence&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${credence}"}</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Carrelage</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp ${"${carrelage}"}</span
        >
      </td>
    </tr>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&Eacute;lectrom&eacute;nager&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${electromenager}"}</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Peinture</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${peinture}"}</span
        >
      </td>
    </tr>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Finitions&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${finitions}"}</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif">Autres</span>
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp; ${"${autres}"}</span
        >
      </td>
    </tr>
  </tbody>
</table>
  
  `,
    ],
    content: [
      `
      <colgroup>
      <col style="width: 49.9086%" />
      <col style="width: 49.9086%" />
    </colgroup>
    <tbody>
      <tr>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&Ecirc;tes-vous satisfait de la prestation de notre intervenant
            ?</span
          >
        </td>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&nbsp; satisfaction &nbsp;</span
          >
        </td>
      </tr>
      <tr>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >L&rsquo;intervention est-elle termin&eacute;e ? (Hors &eacute;ventuel
            SAV)&nbsp;</span
          >
        </td>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun&nbsp;</span
          >
        </td>
      </tr>
      <tr>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&Ecirc;tes-vous satisfait de la propret&eacute; en fin de travaux
            ?&nbsp;</span
          >
        </td>
        <td style="border-width: 0px">
          <span style="font-family: 'times new roman', times, serif"
            >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun &nbsp;</span
          >
        </td>
      </tr>
    </tbody>
  </table>
  <table
  style="
    border-collapse: collapse;
    width: 100%;
    height: 156.75px;
    border-width: 0px;
  "
  border="1"
>
  <colgroup>
    <col style="width: 24.5283%" />
    <col style="width: 28.3019%" />
    <col style="width: 16.0377%" />
    <col style="width: 30.9434%" />
  </colgroup>
  <tbody>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Plan de travail&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Plinthes</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
    </tr>
    <tr style="height: 39.1875px">
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Portes et poign&eacute;es&nbsp;</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&Eacute;lectricit&eacute;</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
    </tr>
    <tr style="height: 39.1875px">
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&Eacute;vier, &eacute;lectricit&eacute; et accessoires&nbsp;</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Plomberie</span
        >
      </td>
      <td style="height: 39.1875px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
    </tr>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Cr&eacute;dence&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Carrelage</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
    </tr>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&Eacute;lectrom&eacute;nager&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun </span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Peinture</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
    </tr>
    <tr style="height: 19.5938px">
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >Finitions&nbsp;</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif">Autres</span>
      </td>
      <td style="height: 19.5938px; border-width: 0px">
        <span style="font-family: 'times new roman', times, serif"
          >&nbsp;&#x2610;Oui &#x2610;Non &#x2713;Aucun</span
        >
      </td>
    </tr>
  </tbody>`,
    ],
  },
  {
    id: "remarque",
    content: ["REMARQUES"],
    contentHTML: [
      `
    <p>
  <span style="font-family: 'times new roman', times, serif">Remarques :&nbsp;</span>
</p>
<p>
  <span style="font-family: 'times new roman', times, serif">&nbsp;${"${remarques}"}&nbsp;</span>
</p>`,
    ],
  },
  {
    id: "signature",
    content: [
      "",
      `
    <p style="text-align: right">
  <span style="font-family: 'times new roman', times, serif"
    >&nbsp;SIGNATURE&nbsp;</span
  >
</p>
    `,
    ],
    contentHTML: [
      ``,
      `<p style="text-align: right">
    <span style="font-family: 'times new roman', times, serif">Signature&nbsp;</span>
  </p>
  <p style="text-align: right">
    <span style="font-family: 'times new roman', times, serif">&nbsp;${"${signature}"}&nbsp;</span>
  </p>`,
    ],
  },
  {
    id: "reserve",
    content: ["RESERVES ET IMAGES"],
    contentHTML: [
      `<p>
    <span style="font-family: 'times new roman', times, serif"><!-- pagebreak --></span>
  </p>
  <p>
    <span style="font-family: 'times new roman', times, serif">Photos&nbsp;</span>
  </p>
  <p>
    <span style="font-family: 'times new roman', times, serif">&nbsp;${"${photos}"}&nbsp;</span>
  </p>
  <p>&nbsp;</p>`,
    ],
  },
];

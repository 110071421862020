import { GroupObj } from "types/models/apps/Group";

export const GET_OWNER_GROUPS = "GET_OWNER_GROUPS";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";

export interface GetOwnerGroupActions {
  type: typeof GET_OWNER_GROUPS;
  payload: {list: GroupObj[], total: number};
}

export interface SetSelectedContact {
  type: typeof SET_SELECTED_GROUP;
  payload: GroupObj;
}

export type GroupActions =
  | GetOwnerGroupActions
  | SetSelectedContact;


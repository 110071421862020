import {
  GET_OWNER_GROUPS,
  GroupActions,
  SET_SELECTED_GROUP,
} from "types/actions/Group";
import { GroupObj } from "types/models/apps/Group";

const initialState: {
  groupList: GroupObj[];
  totalGroups: number;
  selectedGroup: GroupObj | null;
} = {
  groupList: [],
  totalGroups: 0,
  selectedGroup: null,
};

const GroupApp = (state = initialState, action: GroupActions) => {
  switch (action.type) {
    case GET_OWNER_GROUPS:
      console.log("Le payload : ", action.payload);

      return {
        ...state,
        groupList: action.payload.list,
        totalGroups: state.totalGroups + 1,
      };

    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
      };

    default:
      return state;
  }
};
export default GroupApp;

import { Dispatch } from "redux";
import { AppActions } from "../../types";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import {
  CREATE_NEW_TASK,
  DELETE_TASK,
  DELETE_TASK_DETAIL,
  GET_TASK_DETAIL,
  GET_TASK_LIST,
  GET_TODO_FOLDER_LIST,
  GET_TODO_LABEL_LIST,
  GET_TODO_MAGASIN_LIST,
  GET_TODO_PRIORITY_LIST,
  GET_TODO_STAFF_LIST,
  GET_TODO_STATUS_LIST,
  RESET_STORE,
  SEND_TASK_BY_MAIL,
  TOGGLE_TODO_DRAWER,
  UPDATE_TASK_DETAIL,
  UPDATE_TASK_LABEL,
  UPDATE_TASK_STARRED_STATUS,
  UPLOAD_PICTURE,
} from "../../types/actions/Todo.action";
import {
  FolderObj,
  LabelObj,
  MagasinListObj,
  PriorityObj,
  RapportIntervention,
  StatusObj,
} from "../../types/models/apps/Todo";
import jwtAxios from "../../@crema/services/auth/jwt-auth";
import { appIntl } from "@crema/utility/helper/Utils";
import { blue, green, red } from "@mui/material/colors";
import { SearchCriteriaRapport } from "types/models/apps/SearchCrtieriaRapport";
import { FILTER_SEARCH } from "@crema/constants/Filter";
import { onGetReserveList } from "./ReserveApp";
import { GET_EVOLUTION_ABONNEMENT } from "types/actions/Contact.actions";

export const onGetListByCriteria = (
  criteria: SearchCriteriaRapport,
  currentPage: number
) => {
  const { messages } = appIntl();
  const page = currentPage ? currentPage : null;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/rapports/criteria", {
        params: {
          criteria: criteria,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_TASK_LIST,
            payload: { list: data.data.rapport, total: data.data.count },
          });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onGetTaskList = (
  type: string,
  name: string,
  currentPage: number,
  isArchieve: boolean = false
) => {
  const { messages } = appIntl();
  const page = currentPage ? currentPage : null;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/rapports", {
        params: {
          type: type,
          name: name,
          page: page,
          isArchieve: isArchieve,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_TASK_LIST,
            payload: { list: data.data.rapport, total: data.data.count },
          });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onToggleTodoDrawer = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: TOGGLE_TODO_DRAWER });
  };
};

export const onGetToDoLabelList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    const labelList: LabelObj[] = [
      {
        id: 211,
        name: "IMMOCUISINE",
        alias: "IMMOCUISINE",
        label: "IMMOCUISINE",
        value: "IMMOCUISINE",
        color: red[500],
      },
      {
        id: 212,
        name: "NOBLESSA",
        alias: "NOBLESSA",
        label: "NOBLESSA",
        value: "NOBLESSA",
        color: blue[500],
      },
    ];
    dispatch(fetchSuccess());
    dispatch({ type: GET_TODO_LABEL_LIST, payload: labelList });
  };
};
export const onGetToDoStatusList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    const statusList: StatusObj[] = [
      {
        id: 311,
        name: "Avec Réserves",
        alias: FILTER_SEARCH.reserve,
        type: 1,
        color: "#ffb300",
      },
      {
        id: 312,
        name: "Sans Réserves",
        alias: FILTER_SEARCH.notReserve,
        type: 0,
        color: "#000",
      },
    ];
    dispatch(fetchSuccess());
    dispatch({ type: GET_TODO_STATUS_LIST, payload: statusList });
  };
};

export const onGetToDoStaffList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    dispatch(fetchSuccess());
    dispatch({ type: GET_TODO_STAFF_LIST, payload: [] });
  };
};

export const onGetToDoPriorityList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    const priorityList: PriorityObj[] = [
      { id: 4545554, name: "High", type: 1, color: red[500] },
      { id: 9384234, name: "Medium", type: 2, color: blue[500] },
      { id: 4354454, name: "Low", type: 3, color: green[500] },
    ];
    dispatch(fetchSuccess());
    dispatch({ type: GET_TODO_PRIORITY_LIST, payload: priorityList });
  };
};

export const onGetToDoMagasinList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/magasins")
      .then((data) => {
        if (data.status === 200) {
          console.log("Magasins folder for todo => ", data.data);
          const magasinList = data.data.map((d) => {
            return {
              id: d.id,
              name: d.nomMagasin,
              alias: d.nomMagasin.trim(),
              icon: "",
            };
          });
          dispatch(fetchSuccess());
          dispatch({
            type: GET_TODO_MAGASIN_LIST,
            payload: magasinList as MagasinListObj[],
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetToDoFolderList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    const folderList: FolderObj[] = [
      { id: 120, name: "Tous les rapports", alias: FILTER_SEARCH.all },
      {
        id: 130,
        name: "Rapports non archivés",
        alias: FILTER_SEARCH.notArchive,
      },
      { id: 124, name: "Effectuées aujourd'hui", alias: FILTER_SEARCH.today },
      { id: 128, name: "Rapports Favoris", alias: FILTER_SEARCH.starred },
      { id: 129, name: "Rapports archivés", alias: FILTER_SEARCH.archive },
    ];

    dispatch(fetchSuccess());
    dispatch({ type: GET_TODO_FOLDER_LIST, payload: folderList });
  };
};

export const onUpdateTaskLabels = (taskIds: number[], type: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put("/api/todo/update/label", { taskIds, type })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_TASK_LABEL, payload: data.data });
          dispatch(showMessage(String(messages["message.labelUpdatedTo"])));
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onUpdateTaskStarredStatus = (
  taskIds: string,
  status: boolean,
  folderName: string
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put("/api/todo/update/starred", { taskIds, status })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: UPDATE_TASK_STARRED_STATUS,
            payload: { data: data.data, folderName: folderName },
          });
          dispatch(showMessage(String(messages["message.starredStatus"])));
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onDeleteSelectedTasks = (
  taskIds: number[],
  type: string,
  name: string,
  page: number
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete("/rapports", { data: taskIds })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: DELETE_TASK,
            payload: { list: data.data, total: data.data.length },
          });
          dispatch(showMessage(String(messages["task.deleted"])));
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const unArchieveRapportInterventions = (taskIds: number[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/rapports", { data: taskIds })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(showMessage("Rapport desarchivé"));
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onCreateTask = (task: RapportIntervention) => {
  const { messages } = appIntl();
  const { photoEtSignature, ...newTask } = task;
  const lTask = { ...newTask, ...photoEtSignature };
  const { photos, ...mTask } = lTask;
  const newPhotos = photos?.map((photo) => photo.apercu320Url);

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post("/rapports/", { photos: newPhotos!.toString(), ...mTask })
      .then((data) => {
        if (data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: CREATE_NEW_TASK, payload: data.data });
          dispatch(showMessage(String(messages["task.created"])));
          jwtAxios
            .get("/users/abonnement/" + task.clientId)
            .then((data) => {      
              dispatch({
                type: GET_EVOLUTION_ABONNEMENT,
                payload: data.data,
              });
            });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch((error) => {
        if (String(error.message).includes("406")) {
          dispatch(
            fetchError(
              "Vous avez atteint votre limite de création de rapport. Si vous n'êtes pas le responsable du magasin, veuillez contacter ce dernier pour qu'il souscrive à une version supérieur"
            )
          );
        } else
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onGetSelectedTask = (id: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/rapports/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TASK_DETAIL, payload: data.data });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onUpdateSelectedTask = (data: RapportIntervention) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/rapports/" + data.id, data)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_TASK_DETAIL, payload: data.data });
          dispatch(showMessage(String(messages["task.updated"])));
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onSetStarredRapport = (id: number, data: boolean) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/rapports/" + id, { starred: data })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_TASK_DETAIL, payload: data.data });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        console.log("Favorite data => ", { id, data });
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onDeleteSelectedTask = (id: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch(`/rapports/archiver/${id}`)
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: DELETE_TASK_DETAIL, payload: data.data });
          dispatch(showMessage(String(messages["task.deleted"])));
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onUploadPicture = (formData: FormData) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/upload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        if (data.status === 201) {
          console.log("upload result -> " + JSON.stringify(data.data));
          dispatch(fetchSuccess());
          dispatch({ type: UPLOAD_PICTURE, payload: data.data });
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

export const onResetStore = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: RESET_STORE });
  };
};

export const onSendRapportByMail = (id: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post("/rapports/mail", { id })
      .then((data) => {
        if (data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: SEND_TASK_BY_MAIL });
          dispatch(
            showMessage("Le rapport a été envoyé avec succès à votre email")
          );
        } else {
          dispatch(fetchError(String(messages["message.somethingWentWrong"])));
        }
      })
      .catch(() => {
        console.log("Favorite data => ", { id });
        dispatch(fetchError(String(messages["message.somethingWentWrong"])));
      });
  };
};

import React, { useState } from "react";
import { ReactNode, useContext, createContext } from "react";
import { Abonnement } from "types/models/apps/abonnement";

export type AbonnemenContextType = {
  abonnement: { id: number; isAnnual } | undefined;
};

export type AbonnementActionContextType = {
  setAbonnementData: (data: { id: number; isAnnual }) => void;
  setClearData: () => void;
};

const ContextState: AbonnemenContextType = {
  abonnement: undefined,
};

const AbonnementContext = createContext<AbonnemenContextType>(ContextState);
 
const AbonnementActionsContext = createContext<AbonnementActionContextType>({
  setAbonnementData: (data: { id: number; isAnnual }) => {},
  setClearData: () => {},
});

export const useAbonnementContext = () => useContext(AbonnementContext);

export const useAbonnementActionsContext = () =>
  useContext(AbonnementActionsContext);

type Props = {
  children: ReactNode;
};

export const AbonnementContextProvider = ({ children }: Props) => {
  const [value, setvalue] = useState<{ id: number; isAnnual } | undefined>(undefined);
  const setAbonnement = (value: { id: number; isAnnual }) => {
    sessionStorage.setItem("abonnement", JSON.stringify(value));
    setvalue(value);
  };

  const setClear = () => {
    sessionStorage.clear();
    setvalue(undefined);
  };

  React.useEffect(() => { 
    const Abonnementvalue = sessionStorage.getItem("abonnement");
    if (Abonnementvalue) {
      const AbonnementvalueParse = JSON.parse(Abonnementvalue) as { id: number; isAnnual };
      setvalue(AbonnementvalueParse);
    }
  }, []);

  return (
    <AbonnementContext.Provider value={{ abonnement: value }}>
      <AbonnementActionsContext.Provider
        value={{ setAbonnementData: setAbonnement, setClearData: setClear }}
      >
        {children}
      </AbonnementActionsContext.Provider>
    </AbonnementContext.Provider>
  );
};

export default AbonnementContextProvider;

import { TemplateObj } from "types/models/apps/Template";

export const GET_TEMPLATE_ACTION = "GET_TEMPLATE_ACTION";
export const SAVE_TEMPLATE_ACTION = "SAVE_TEMPLATE_ACTION";

export interface GetTemplateAction {
  type: typeof GET_TEMPLATE_ACTION;
  payload: TemplateObj;
}

export interface SaveTemplateAction {
  type: typeof SAVE_TEMPLATE_ACTION;
  payload: TemplateObj;
}

export type TemplateActions = GetTemplateAction | SaveTemplateAction;

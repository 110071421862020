import React from "react";
import {
  alpha,
  Box,
  Button,
  FormControlLabel,
  Select,
  Switch,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Field, Form } from "formik";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import IntlMessages from "@crema/utility/IntlMessages";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Fonts } from "shared/constants/AppEnums";
import EditIcon from "@mui/icons-material/Edit";
import AppGridContainer from "@crema/core/AppGridContainer";
import Grid from "@mui/material/Grid";
import AppTextField from "@crema/core/AppFormComponents/AppTextField";
import { styled } from "@mui/material/styles";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { ContactObj } from "types/models/apps/Contact";

const HeaderWrapper = styled("div")(({ theme }) => {
  return {
    padding: 20,
    marginLeft: -24,
    marginRight: -24,
    marginTop: -20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& .dropzone": {
      outline: 0,
      "&:hover .edit-icon, &:focus .edit-icon": {
        display: "flex",
      },
    },
  };
});

const AvatarViewWrapper = styled("div")(({ theme }) => {
  return {
    position: "relative",
    cursor: "pointer",
    "& .edit-icon": {
      position: "absolute",
      bottom: 0,
      right: 0,
      zIndex: 1,
      border: `solid 2px ${theme.palette.background.paper}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.7),
      color: theme.palette.primary.contrastText,
      borderRadius: "50%",
      width: 26,
      height: 26,
      display: "none",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.4s ease",
      "& .MuiSvgIcon-root": {
        fontSize: 16,
      },
    },
  };
});

interface CreateUserFormProps {
  values: ContactObj;
  userImage: string;
  setUserImage: (image: string) => void;
  readOnly?: boolean;
}

const CreateUserForm: React.FC<CreateUserFormProps> = ({
  values,
  userImage,
  setUserImage,
  readOnly,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUserImage(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const rolesList = [
    {
      id: 1,
      key: "SUDO",
      label: "ADMINISTRATEUR",
    },
    {
      id: 2,
      key: "USER",
      label: "POSEUR",
    },
    {
      id: 3,
      key: "RESPONSABLE",
      label: "RESPONSABLE",
    },
    {
      id: 4,
      key: "MANAGER",
      label: "MANAGER",
    },
    {
      id: 5,
      key: "CONCEPTEUR_VENDEUR",
      label: "CONCEPTEUR VENDEUR",
    },
  ];

  const { messages } = useIntl();

  const { user } = useAuthUser();

  return (
    <Form noValidate autoComplete="off">
      <HeaderWrapper>
        {!readOnly && (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <label htmlFor="icon-button-file">
              <AvatarViewWrapper>
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                  src={userImage ? userImage : ""}
                />
                <Box className="edit-icon">
                  <EditIcon />
                </Box>
              </AvatarViewWrapper>
            </label>
          </div>
        )}
        {values.name ? (
          <Box component="h4" fontWeight={Fonts.SEMI_BOLD} mt={2}>
            {values.name}
          </Box>
        ) : null}
      </HeaderWrapper>

      <Box
        sx={{
          padding: 5,
          ml: -6,
          mr: -6,
        }}
      >
        <Box
          sx={{
            pb: 5,
            px: 5,
            mx: -5,
            mb: 5,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            component="h6"
            sx={{
              mb: { xs: 4, xl: 6 },
              fontSize: 14,
              fontWeight: Fonts.SEMI_BOLD,
            }}
          >
            <IntlMessages id="contactApp.personalDetails" />
          </Box>

          <div>
            <AppTextField
              sx={{
                width: "100%",
                mb: { xs: 4, xl: 6 },
              }}
              variant="outlined"
              label={<IntlMessages id="common.name" />}
              name="name"
              disabled={readOnly}
            />
            <AppTextField
              sx={{
                width: "100%",
                mb: { xs: 4, xl: 6 },
              }}
              variant="outlined"
              label="Prenom"
              name="surname"
              disabled={readOnly}
            />

            <AppTextField
              sx={{
                width: "100%",
                mb: { xs: 4, xl: 6 },
              }}
              variant="outlined"
              label={<IntlMessages id="common.email" />}
              name="email"
              disabled={readOnly}
            />

            <AppTextField
              sx={{
                width: "100%",
                mb: { xs: 4, xl: 6 },
              }}
              variant="outlined"
              label={<IntlMessages id="common.phone" />}
              name="phoneNumber"
              disabled={readOnly}
            />

            <AppGridContainer spacing={5}>
              <Grid item xs={12} md={6}>
                {user?.email !== values.email && (
                  <FormControl
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                    disabled={readOnly}
                  >
                    <InputLabel id="label-select-outlined-label">
                      Rôle
                    </InputLabel>
                    <Field
                      name="role"
                      label="role"
                      labelid="label-select-outlined-label"
                      as={Select}
                      sx={{
                        width: "100%",
                        mb: { xs: 4, xl: 6 },
                      }}
                    >
                      {rolesList.map((role) => {
                        return (
                          <MenuItem
                            value={role.key}
                            key={role.id}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            {role.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {user?.email !== values.email ? (
                  <FormControl
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                    disabled={readOnly}
                  >
                    <FormControlLabel
                      sx={{
                        mb: { xs: 2, xl: 3 },
                      }}
                      control={
                        <Field
                          name="active"
                          defaultChecked={values.active ? values.active : false}
                          label="active"
                          labelid="label-active"
                          as={Switch}
                          sx={{
                            mb: { xs: 2, xl: 3 },
                          }}
                        ></Field>
                      }
                      label="Actif"
                    />
                  </FormControl>
                ) : null}
              </Grid>
            </AppGridContainer>
          </div>
        </Box>

        <Box
          sx={{
            pb: 5,
            px: 5,
            mx: -5,
            mb: 5,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            component="h6"
            sx={{
              mb: { xs: 4, xl: 6 },
              fontSize: 14,
              fontWeight: Fonts.SEMI_BOLD,
            }}
          >
            <IntlMessages id="common.otherDetails" />
          </Box>

          <div>
            <AppTextField
              sx={{
                width: "100%",
                mb: { xs: 4, xl: 6 },
              }}
              variant="outlined"
              label="Société"
              name="company"
              disabled={readOnly}
            />

            <AppTextField
              sx={{
                width: "100%",
                mb: { xs: 4, xl: 6 },
              }}
              variant="outlined"
              label="Adresse"
              name="address"
              disabled={readOnly}
            />
          </div>
        </Box>
        {user?.email === values.email && (
          <Box
            sx={{
              pb: 5,
              px: 5,
              mx: -5,
              mb: 5,
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              component="h6"
              sx={{
                mb: { xs: 4, xl: 6 },
                fontSize: 14,
                fontWeight: Fonts.SEMI_BOLD,
              }}
            >
              <IntlMessages id="common.password" />
            </Box>

            <div>
              <AppTextField
                sx={{
                  width: "100%",
                  mb: { xs: 4, xl: 6 },
                }}
                variant="outlined"
                label="Mot de passe"
                type="password"
                name="password"
              />

              <AppTextField
                sx={{
                  width: "100%",
                }}
                variant="outlined"
                label="Retaper le mot de passe"
                type="password"
                name="confirmPassword"
              />
            </div>
          </Box>
        )}

        {user?.email !== values.email && (
          <Box>
            <div>
              <Box
                component="h6"
                sx={{
                  mb: { xs: 4, xl: 6 },
                  fontSize: 14,
                  fontWeight: Fonts.SEMI_BOLD,
                }}
              >
                <IntlMessages id="common.notes" />
              </Box>
              <AppTextField
                name="notes"
                multiline
                sx={{
                  width: "100%",
                }}
                rows="4"
                variant="outlined"
                placeholder={messages["common.notes"] as string}
                disabled={readOnly}
              />
            </div>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          pb: 4,
          mx: -1,
          textAlign: "right",
        }}
      >
        <Button
          sx={{
            position: "relative",
            minWidth: 100,
          }}
          color="primary"
          variant="outlined"
          type="submit"
          disabled={readOnly}
        >
          <IntlMessages id="common.save" />
        </Button>
      </Box>
    </Form>
  );
};

export default CreateUserForm;

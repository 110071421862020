import {
  GET_METRIQUES_FAMILLE_PRODUIT,
  GET_METRIQUES_SATISFACTION_CLIENT,
  GET_NOMBRE_RESERVE,
  GET_NOMBRE_SAV_PAR_POSEUR,
  GET_ORIGINE_SAV,
  GET_STATISTIQUE_HEBDOMADAIRE,
  MetriquesActions,
} from "types/actions/Metriques.actions";
import {
  MetriquesFamilleProduitObj,
  MetriquesOrigineSAVObj,
  MetriquesReserveObj,
  MetriquesSAVParPoseurObj,
  MetriquesSatisfactionObj,
  StatistiqueHebdomadaireObj,
} from "types/models/apps/Metriques";

const initialState: {
  metriquesSatisfaction: MetriquesSatisfactionObj[];
  nombreSAVParPoseur: MetriquesSAVParPoseurObj[];
  reserves: MetriquesReserveObj[];
  origineSAV: MetriquesOrigineSAVObj[];
  familleProduit: MetriquesFamilleProduitObj[];
  statistiqueHebdomadaire: StatistiqueHebdomadaireObj[];
} = {
  metriquesSatisfaction: [],
  nombreSAVParPoseur: [],
  reserves: [],
  origineSAV: [],
  familleProduit: [],
  statistiqueHebdomadaire: []
};

const MetriquesApp = (state = initialState, action: MetriquesActions) => {
  switch (action.type) {
    case GET_METRIQUES_SATISFACTION_CLIENT:
      return {
        ...state,
        metriquesSatisfaction: action.payload,
      };
    case GET_NOMBRE_SAV_PAR_POSEUR:
      return {
        ...state,
        nombreSAVParPoseur: action.payload,
      };
    case GET_NOMBRE_RESERVE:
      return {
        ...state,
        reserves: action.payload,
      };
    case GET_ORIGINE_SAV:
      return {
        ...state,
        origineSAV: action.payload,
      };
    case GET_METRIQUES_FAMILLE_PRODUIT:
      return {
        ...state,
        familleProduit: action.payload,
      };
    case GET_STATISTIQUE_HEBDOMADAIRE:
      return {
        ...state,
        statistiqueHebdomadaire: action.payload,
      };
    default:
      return state;
  }
};

export default MetriquesApp;

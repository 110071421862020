import {
  MetriquesOrigineSAVObj,
  MetriquesReserveObj,
  MetriquesSAVParPoseurObj,
  MetriquesSatisfactionObj,
  StatistiqueHebdomadaireObj,
} from "types/models/apps/Metriques";

export const GET_METRIQUES_SATISFACTION_CLIENT =
  "GET_METRIQUES_SATISFACTION_CLIENT";

export const GET_METRIQUES_RESERVE = "GET_METRIQUES_RESERVE";
export const GET_NOMBRE_SAV_PAR_POSEUR = "GET_NOMBRE_SAV_PAR_POSEUR";
export const GET_NOMBRE_RESERVE = "GET_NOMBRE_RESERVE";
export const GET_ORIGINE_SAV = "GET_ORIGINE_SAV";
export const GET_METRIQUES_FAMILLE_PRODUIT = "GET_METRIQUES_FAMILLE_PRODUIT";
export const GET_STATISTIQUE_HEBDOMADAIRE = "GET_STATISTIQUE_HEBDOMADAIRE"

export interface GetMetriqueSatisfactionClient {
  type: typeof GET_METRIQUES_SATISFACTION_CLIENT;
  payload: MetriquesSatisfactionObj[];
}

export interface GetMetriqueReserve {
  type: typeof GET_METRIQUES_RESERVE;
  payload: MetriquesSatisfactionObj[];
}

export interface GetNombreSAVParPoseur {
  type: typeof GET_NOMBRE_SAV_PAR_POSEUR;
  payload: MetriquesSAVParPoseurObj[];
}

export interface GetNombrReserve {
  type: typeof GET_NOMBRE_RESERVE;
  payload: MetriquesReserveObj[];
}

export interface GetOrigineSAV {
  type: typeof GET_ORIGINE_SAV;
  payload: MetriquesOrigineSAVObj[];
}

export interface GetFamilleProduit {
  type: typeof GET_METRIQUES_FAMILLE_PRODUIT;
  payload: MetriquesOrigineSAVObj[];
}

export interface GetStatistiqueHebdomadaire {
  type: typeof GET_STATISTIQUE_HEBDOMADAIRE;
  payload: StatistiqueHebdomadaireObj[];
}

export type MetriquesActions =
  | GetMetriqueSatisfactionClient
  | GetMetriqueReserve
  | GetNombreSAVParPoseur
  | GetNombrReserve
  | GetOrigineSAV
  | GetFamilleProduit
  | GetStatistiqueHebdomadaire;

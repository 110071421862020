import { Dispatch } from "redux";
import { AppActions } from "../../types";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import {
  CREATE_NEW_CONTACT,
  DELETE_CONTACT,
  GET_CONTACT_BY_ID,
  GET_CONTACT_FOLDER_LIST,
  GET_CONTACT_LABEL_LIST,
  GET_CONTACT_LIST,
  GET_CUISINISTE_LIST,
  GET_EVOLUTION_ABONNEMENT,
  IMPORT_CONTACTS,
  SUCCESS_ADD_PASSWORD,
  SUCCESS_VERIFY_TOKEN,
  TOGGLE_CONTACT_DRAWER,
  UPDATE_CONTACT_DETAIL,
  UPDATE_CONTACT_LABEL,
  UPDATE_CONTACT_STARRED_STATUS,
} from "../../types/actions/Contact.actions";
import {
  ContactObj,
  FolderObj,
  LabelObj,
  PasswordContact,
  UpdateContactObj,
} from "../../types/models/apps/Contact";
import jwtAxios from "../../@crema/services/auth/jwt-auth";
import { appIntl } from "@crema/utility/helper/Utils";
import { blue, green, red } from "@mui/material/colors";
import messages from "@crema/services/db/messages";
import { userRole } from "types/models/AuthUser";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { Abonnement } from "types/models/apps/abonnement";

export const onGetContactList = (
  type: string,
  name: string,
  currentPage: number
) => {
  const { messages } = appIntl();
  const page = currentPage ? currentPage : 0;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/users/", {
        params: {
          type: type,
          name: name,
          page: page,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CONTACT_LIST,
            payload: { list: data.data, total: data.data.length },
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetEvolutionAbonnement = (userId: number) => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/users/abonnement/${userId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_EVOLUTION_ABONNEMENT,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetCuisiniste = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/users/", {
        params: {
          name: "responsable",
        },
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CUISINISTE_LIST,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetLabelList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    const labelList: LabelObj[] = [
      { id: 311, name: "Crema", alias: "crema", color: red[500] },
      { id: 312, name: "Personal", alias: "personal", color: blue[500] },
      { id: 313, name: "Work", alias: "work", color: green[500] },
    ];
    dispatch(fetchSuccess());
    dispatch({ type: GET_CONTACT_LABEL_LIST, payload: labelList[0] });
  };
};

export const onGetFolderList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    const folderList: FolderObj[] = [
      { id: 121, name: "Tous", alias: "all" },
      { id: 122, name: "Poseurs", alias: "poseurs" },
      { id: 123, name: "Responsable", alias: "responsable" },
      { id: 124, name: "Manager", alias: "manager" },
      { id: 125, name: "Concepteur_vendeur", alias: "concepteur_vendeur" },
    ];

    dispatch(fetchSuccess());
    dispatch({ type: GET_CONTACT_FOLDER_LIST, payload: folderList });
  };
};

export const onToggleContactDrawer = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: TOGGLE_CONTACT_DRAWER });
  };
};

export const onUpdateContactLabel = (
  contactIds: number[],
  type: number,
  labelName: string
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put("/api/contactApp/update/label", { contactIds, type })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: UPDATE_CONTACT_LABEL,
            payload: { data: data.data, labelName: labelName, labelType: type },
          });
          dispatch(showMessage(messages["message.labelUpdated"] as string));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onUpdateAbonnentUser = (
  abonnement: { id: number; isAnnual: boolean },
  id: number
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/users/abonnements/" + id, { abonnement })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch(showMessage("Vous venez de vous abonner"));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteAbonnentUser = (id: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/users/abonnement/resiliate/" + id)
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch(showMessage("Vous venez de vous abonner"));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onUpdateStarredStatus = (
  contactIds: number[],
  status: boolean,
  folderName: string
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put("/api/contactApp/update/starred", { contactIds, status })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: UPDATE_CONTACT_STARRED_STATUS,
            payload: { data: data.data, folderName: folderName },
          });
          dispatch(showMessage(messages["message.starredStatus"] as string));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onDeleteContacts = (
  type: string,
  name: string,
  contactIds: number[],
  page: number
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .delete("/users/", { data: contactIds })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: DELETE_CONTACT,
            payload: { list: data.data, total: data.data.length },
          });
          dispatch(showMessage(messages["message.contactDeleted"] as string));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onUpdateSelectedContact = (
  contact: UpdateContactObj,
  message: string
) => {
  const { messages } = appIntl();
  const { confirmPassword, magasins, password, ...updateData } = contact;
  updateData.newPassword = password;
  const magasinIds = magasins?.map((magasin) => {
    return { id: magasin.id };
  });
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/users/" + contact.id, { updateData, magasinIds })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: UPDATE_CONTACT_DETAIL, payload: data.data });
          dispatch(showMessage(message as string));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onGetContactById = (id?: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/users/" + id ?? 1)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: GET_CONTACT_BY_ID,
            payload: data.data as ContactObj,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onCreateContact = (contact: ContactObj) => {
  const { messages } = appIntl();
  const { confirmPassword, magasins, ...newContact } = contact;
  const magasinIds = magasins
    ? magasins?.map((magasin) => {
        return { id: magasin.id };
      })
    : undefined;
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post("/auth/register", { magasinIds, ...newContact })
      .then((data) => {
        if (data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: CREATE_NEW_CONTACT, payload: data.data });
          dispatch(showMessage(messages["message.contactCreated"] as string));
          jwtAxios.get("/users/abonnement/0").then((data) => {
            dispatch({
              type: GET_EVOLUTION_ABONNEMENT,
              payload: data.data,
            });
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onCreateContacts = (contacts: ContactObj[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    for (const contact of contacts) {
      contact.active = !!contact.active;
      const { confirmPassword, ...newContact } = contact;
      jwtAxios
        .post("/auth/register", newContact)
        .then((data) => {
          if (data.status === 201) {
            dispatch(fetchSuccess());
            dispatch({ type: CREATE_NEW_CONTACT, payload: data.data });
            dispatch(showMessage(messages["message.contactCreated"] as string));
            jwtAxios.get("/users/abonnement/0").then((data) => {
              dispatch({
                type: GET_EVOLUTION_ABONNEMENT,
                payload: data.data,
              });
            });
          } else {
            dispatch(
              fetchError(messages["message.somethingWentWrong"] as string)
            );
          }
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });
    }
  };
};

export const onImportContact = (contacts: ContactObj[]) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`auth/multiple-register`, contacts)
      .then((data) => {
        if (data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({ type: IMPORT_CONTACTS, payload: data.data });
          dispatch(showMessage(messages["message.contactsImported"] as string));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onRefreshContacts = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: IMPORT_CONTACTS, payload: [] });
  };
};

export const onAddPassword = (data: PasswordContact) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/auth/password", data)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: SUCCESS_ADD_PASSWORD, payload: true });
          dispatch(showMessage("Le mot de passe a été crée."));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onResendLink = (email: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .patch("/auth/resend-link", { email })
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(showMessage("Email renvoyé avec success."));
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 409)
          // J'ai customisé mon erreur à une erreur de conflit
          dispatch(
            showMessage(
              "Pour cet utilisateur, il existe un token, Veuillez patienter." as string
            )
          );
        else dispatch(fetchError(error.message as string));
      });
  };
};

export const verifyToken = (token: string, userId: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    jwtAxios
      .get(`/auth/verify-token/${token}/${userId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({
            type: SUCCESS_VERIFY_TOKEN,
            payload: "success",
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: SUCCESS_VERIFY_TOKEN,
          payload: "error",
        });
        dispatch(fetchError(error.message));
      });
  };
};

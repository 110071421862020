import { FolderObj, LabelObj } from "../models/apps/Contact";
import { MagasinObj } from "../models/apps/Magasin";

export const CREATE_NEW_MAGASIN = "CREATE_NEW_MAGASIN";
export const DELETE_MAGASIN = "DELETE_MAGASIN";
export const GET_MAGASIN_FOLDER_LIST = "GET_MAGASIN_FOLDER_LIST";
export const GET_MAGASIN_LABEL_LIST = "GET_MAGASIN_LABEL_LIST";
export const GET_MAGASIN_LIST = "GET_MAGASIN_LIST";
export const GET_OWNER_MAGASINS = "GET_OWNER_MAGASINS";
export const GET_SELECTED_USER_MAGASINS = "GET_SELECTED_USER_MAGASINS";
export const UPDATE_MAGASIN_STARRED_STATUS = "UPDATE_MAGASIN_STARRED_STATUS";
export const UPDATE_MAGASIN_DETAIL = "UPDATE_MAGASIN_DETAIL";
export const UPDATE_MAGASIN_LABEL = "UPDATE_MAGASIN_LABEL";
export const TOGGLE_MAGASIN_DRAWER = "TOGGLE_MAGASIN_DRAWER";

export interface GetMagasinFolderActions {
  type: typeof GET_MAGASIN_FOLDER_LIST;
  payload: FolderObj[];
}

export interface GetMagasinLabelActions {
  type: typeof GET_MAGASIN_LABEL_LIST;
  payload: LabelObj;
}

export interface GetMagasinsActions {
  type: typeof GET_MAGASIN_LIST;
  payload: { list: MagasinObj[]; total: number };
}

export interface GetOwnerMagasinsActions {
  type: typeof GET_OWNER_MAGASINS;
  payload: { list: MagasinObj[]; total: number };
}

export interface GetSelectedUserMagasinsActions {
  type: typeof GET_SELECTED_USER_MAGASINS;
  payload: { list: MagasinObj[]; total: number };
}

export interface UpdateMagasinStarActions {
  type: typeof UPDATE_MAGASIN_STARRED_STATUS;
  payload: { data: MagasinObj[]; folderName: string };
}

export interface UpdateMagasinActions {
  type: typeof UPDATE_MAGASIN_DETAIL;
  payload: MagasinObj;
}

export interface UpdateMagasinLabelActions {
  type: typeof UPDATE_MAGASIN_LABEL;
  payload: { data: MagasinObj[]; labelName: string; labelType: number };
}

export interface CreateMagasinActions {
  type: typeof CREATE_NEW_MAGASIN;
  payload: MagasinObj;
}

export interface DeleteMagasinActions {
  type: typeof DELETE_MAGASIN;
  payload: { list: MagasinObj[]; total: number };
}

export interface ToggleContactDrawerActions {
  type: typeof TOGGLE_MAGASIN_DRAWER;
}

export type MagasinActions =
  | GetMagasinFolderActions
  | GetMagasinLabelActions
  | GetMagasinsActions
  | UpdateMagasinStarActions
  | UpdateMagasinActions
  | UpdateMagasinLabelActions
  | CreateMagasinActions
  | ToggleContactDrawerActions
  | GetOwnerMagasinsActions
  | GetSelectedUserMagasinsActions
  | DeleteMagasinActions;

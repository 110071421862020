import { dataInfo } from "pages/template/Drap and drop/vertical/data";
import {
  GET_TEMPLATE_ACTION,
  SAVE_TEMPLATE_ACTION,
  TemplateActions,
} from "types/actions/Template.actions";
import { NewTemplateDto, TemplateObj } from "types/models/apps/Template";
const initialState: {
  template: NewTemplateDto;
} = {
  template: { id: 1, model: dataInfo, type: "RAPPORT", userId: 1 },
};
 
const TemplateApp = (state = initialState, action: TemplateActions) => {
  const { payload, type } = action;
  switch (type) {
    case GET_TEMPLATE_ACTION: {
      return {
        ...state,
        template: payload,
      };
    }
    case SAVE_TEMPLATE_ACTION: {
      return {
        ...state,
        template: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default TemplateApp;

import { MembershipObj } from "types/models/apps/Membership";

export const CREATE_GROUP_MEMBERSHIP = "CREATE_GROUP_MEMBERSHIP";
export const UPDATE_GROUP_MEMBERSHIP = "UPDATE_GROUP_MEMBERSHIP";
export const GET_GROUP_MEMBERSHIPS = "GET_GROUP_MEMBERSHIPS";
export const DELETE_GROUP_MEMBERSHIP = "DELETE_GROUP_MEMBERSHIP";

export interface CreateGroupMembershipActions {
  type: typeof CREATE_GROUP_MEMBERSHIP;
  payload: MembershipObj;
}

export interface UpdateGroupMembershipActions {
  type: typeof UPDATE_GROUP_MEMBERSHIP;
  payload: MembershipObj;
}

export interface DeleteGroupMembershipActions {
  type: typeof DELETE_GROUP_MEMBERSHIP;
  payload: MembershipObj;
}

export interface GetGroupMembershipActions {
  type: typeof GET_GROUP_MEMBERSHIPS;
  payload: {list: MembershipObj[], length: number};
}

export type MembershipActions =
  | CreateGroupMembershipActions
  | UpdateGroupMembershipActions
  | DeleteGroupMembershipActions
  | GetGroupMembershipActions;


import {
  CREATE_NEW_MAGASIN,
  DELETE_MAGASIN,
  GET_MAGASIN_FOLDER_LIST,
  GET_MAGASIN_LIST,
  GET_OWNER_MAGASINS,
  GET_SELECTED_USER_MAGASINS,
  MagasinActions,
  UPDATE_MAGASIN_DETAIL,
} from "../../types/actions/Magasin.actions";
import {
  ContactObj,
  FolderObj,
  LabelObj,
} from "../../types/models/apps/Contact";
import { MagasinObj } from "../../types/models/apps/Magasin";

const initialState: {
  magasinList: MagasinObj[];
  ownerMagasins: MagasinObj[];
  userMagasins: MagasinObj[];
  totalMagasins: number;
  magasinDrawer: false;
  labelList: LabelObj[];
  folderList: FolderObj[];
  selectedMagasin: MagasinObj | null;
} = {
  magasinList: [],
  ownerMagasins:[],
  userMagasins: [],
  totalMagasins: 0,
  magasinDrawer: false,
  labelList: [],
  folderList: [],
  selectedMagasin: null,
};

const MagasinApp = (state = initialState, action: MagasinActions) => {
  switch (action.type) {
    case GET_MAGASIN_LIST:
      return {
        ...state,
        magasinList: action.payload.list,
        totalMagasins: action.payload.total,
      };

    case GET_OWNER_MAGASINS:
      return {
        ...state,
        ownerMagasins: action.payload.list,
        totalMagasins: action.payload.total,
      };

    case GET_SELECTED_USER_MAGASINS:
      return {
        ...state,
        userMagasins: action.payload.list,
        totalMagasins: action.payload.total,
      };

    case GET_MAGASIN_FOLDER_LIST:
      return {
        ...state,
        folderList: action.payload,
      };

    case CREATE_NEW_MAGASIN:
      return {
        ...state,
        magasinList: [action.payload, ...state.magasinList],
        totalMagasins: state.totalMagasins + 1,
      };

    case DELETE_MAGASIN: {
      const ids = action.payload.list.map((value) => value.id!);
      return {
        ...state,
        magasinList: state.magasinList.filter(
          (magasin) => !ids.includes(magasin.id!)
        ),
        totalMagasins: state.totalMagasins - action.payload.total,
      };
    }

    case UPDATE_MAGASIN_DETAIL:
      return {
        ...state,
        selectedMagasin: action.payload,
        magasinList: state.magasinList.map((magasin) =>
          magasin.id === action.payload.id ? action.payload : magasin
        ),
      };

    default:
      return state;
  }
};
export default MagasinApp;

import React from "react";
import { RoutePermittedRole } from "../../shared/constants/AppConst";

export const reserveRouteConfig = [

  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Responsable,
      RoutePermittedRole.GestionMagasins,
      RoutePermittedRole.GestionGroupes,
      RoutePermittedRole.GestionReserves,
      RoutePermittedRole.User,
      RoutePermittedRole.Concepteur_vendeur,
      RoutePermittedRole.Manager
    ],
    path:  ["/reserves/rapports/rapport/:id","/reserves/rapports"],
    component: React.lazy(() => import("./index")),
  }
];
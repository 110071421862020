import jwtAxios from "@crema/services/auth/jwt-auth";
import { appIntl } from "@crema/utility/helper/Utils";
import { Dispatch } from "redux";
import { AppActions } from "../../types";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import { CREATE_INTENT } from "types/actions/Stripe.actions";
import { getMessageErreur } from "shared/functions";

export const onCreateIntent = (
  userId: number,
  id: number,
  isAnnual = false
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/stripe/create-intent`, { userId, id, isAnnual })
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          dispatch(fetchSuccess());
          dispatch({
            type: CREATE_INTENT,
            payload: data.data,
          });
        } else {
          dispatch(
            fetchError(messages["message.somethingWentWrong"] as string)
          );
        }
      })
      .catch((error: unknown) => {
        dispatch(fetchError(getMessageErreur(error)));
      });
  };
};

import { AxiosError } from "axios";

export function getMessageErreur(error: unknown) {
  let message: string; //recupere le message
  if (!!error && typeof error === "object" && "response" in error) {
    const axiosError = error as AxiosError;
    message = axiosError.response?.data.message ?? "Erreur de connexion au serveur"; // si erreur vide afficher ce message
  } else if (error instanceof Error) {
    message = error.message;
  } else {
    message = "Erreur inconnue: " + error;
  }
  return message;
}
import { io, Socket } from 'socket.io-client'

const debug = false;
const verbose = false;
const socketURL = process.env.REACT_APP_SOCKET_API_URL ? process.env.REACT_APP_SOCKET_API_URL : "http://localhost:4500/notifications"

interface ServerToClientEvents {
    msgToClient: (data: string) => void;
    tokenInvalid: () => void;
}

interface ClientToServerEvents { }

interface ExtendedSocket extends Socket<ServerToClientEvents, ClientToServerEvents> { }

const socket: ExtendedSocket = io(socketURL, {
    autoConnect: false,
});

export function connectToSocket(token: string) {
    if (socket.connected || !token) return
    if (debug) console.log('Socket: connecting', socket)
    socket.auth = { token }
    socket.connect()
}

export function disconnectFromSocket() {
    if (debug) console.log('Socket: disconnecting', socket);
    socket.auth = {};
    socket.disconnect();
}

// Any event

socket.onAny((event, ...args) => {
    if (debug && verbose) console.log('Socket: any', event, args, socket); // catch-all listener
});

// Reserved events

socket.on('connect', () => {
    const engine = socket.io.engine;
    if (debug) console.log('Socket: connect', engine.transport.name, socket); // in most cases, "polling"

    engine.once('upgrade', (transport) => {
        if (debug) console.log('Socket: upgrade', transport, socket); // in most cases, "websocket"
    });

    engine.on('packet', (packet) => {
        if (debug && verbose) console.log('Socket: packet', packet, socket); // packet: {type, data} // called for each packet received
    });

    engine.on('packetCreate', (packet) => {
        if (debug && verbose) console.log('Socket: packetCreate', packet, socket); // packet: {type, data} // called for each packet sent
    });

    engine.on('drain', () => {
        if (debug && verbose) console.log('Socket: drain', socket); // called when the write buffer is drained
    });

    engine.on('close', (reason, desc) => {
        if (debug) console.log('Socket: close', reason, desc, socket); // called when the underlying connection is closed
    });
});

socket.on('connect_error', (err) => {
    if (debug) console.log('Socket: connect_error', err, socket);
    // try again
    setTimeout(() => {
        socket.connect();
    }, 8000);
});


socket.on('disconnect', (reason) => {
    if (debug) console.log('Socket: disconnect', reason, socket);
    if (reason === 'io server disconnect' && (socket.auth as any)?.token) {
        setTimeout(() => {
            socket.connect();
        }, 8000);
    }
});

// User events

type WebSocketNotificationCategories = 'NotificationTable';

export enum WebSocketNotificationsOperations {
    Insert = 'INSERT',
    Update = 'UPDATE',
    Delete = 'DELETE',
}

export enum WebSocketNotificationsTable {
    Pose = 'Pose',
    Magasin = 'Magasin',
    User = 'User',
    Contremarque = 'Contremarque',
    Reserve = 'Reserve',
}


export interface WebSocketNotifications<T> {
    table: WebSocketNotificationsTable;
    operation: WebSocketNotificationsOperations;
}

socket.on('msgToClient', (data) => {
    const notification = data ? (JSON.parse(data) as WebSocketNotifications<any>) : undefined
    if (notification) {
        console.log('Socket: msgToClient', notification)
        document.dispatchEvent(new CustomEvent<WebSocketNotifications<any>>('notification', { detail: notification }))
        return
    }
    console.log('Socket: msgToClient - ERROR: pas de notification')
})

socket.on('tokenInvalid', () => {

    if (debug) console.log('Socket: token_invalid', socket);
    disconnectFromSocket();
});
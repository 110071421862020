import AppDialog from "@crema/core/AppDialog";
import { useAuthUser } from "@crema/utility/AuthHooks";
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import {
  onCreateContact,
  onUpdateSelectedContact,
} from "redux/actions/ContactApp";
import { ContactObj } from "types/models/apps/Contact";
import * as yup from "yup";
import CreateUserForm from "./CreateUserForm";
import { userRole } from "types/models/AuthUser";

interface CreateUserProps {
  onOpen: boolean;
  selectContact?: ContactObj | null;
  onClose: () => void;
  readOnly?: boolean;
}

const CreateUser: React.FC<CreateUserProps> = ({
  onOpen,
  selectContact,
  onClose,
  readOnly,
}) => {
  const { user } = useAuthUser();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    name: yup.string().required("Le nom est obligatoire"),
    surname: yup.string().required("Le prénom est obligatoire"),
    email: yup.string().email().required("L'adresse email est invalide"),
    phoneNumber: yup
      .string()
      .matches(
        /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
        "Le numero de téléphone n'est pas valide"
      )
      .nullable()
      .required("Le numero de telephone est requis"),
    password: yup
      .string()
      .min(8, "Le mot de passe doit avoir minimum 8 caractères"),
    confirmPassword: yup
      .string()
      .when("password", (password: string, schema) => {
        if (password && password.length >= 8)
          return schema
            .required("Veuillez confirmer le mot de passe")
            .oneOf(
              [yup.ref("password"), null],
              "Les mots de passe doivent correspondre"
            );
        return schema;
      }),
  });

  const [userImage, setUserImage] = React.useState(
    selectContact && selectContact.profilePictureUrl
      ? selectContact.profilePictureUrl
      : ""
  );

  const createContact = (objet: ContactObj) => {
    dispatch(onCreateContact(objet));
  };

  return (
    <AppDialog fullHeight open={onOpen} onClose={() => onClose()}>
      <Formik
        validateOnChange={true}
        initialValues={{
          name: selectContact ? selectContact.name : "",
          surname: selectContact ? selectContact.surname : "",
          email: selectContact ? selectContact.email : "",
          phoneNumber: selectContact ? selectContact.phoneNumber : "",
          company:
            selectContact && selectContact.company ? selectContact.company : "",
          address:
            selectContact && selectContact.address ? selectContact.address : "",
          notes:
            selectContact && selectContact.notes ? selectContact.notes : "",
          role:
            selectContact && selectContact.role ? selectContact.role : "USER",
          active: selectContact && selectContact.active ? true : false,
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          console.log("in OnSUbmit ---> " + JSON.stringify(data));
          if (selectContact) {
            const newContact = {
              id: selectContact.id,
              profilePictureUrl: userImage,
              ...data,
              role: userRole[data.role],
            };
            const message =
              user?.email == selectContact.email
                ? "Votre profil a bien été mise à jour"
                : "Le contact a bien été mise à jour";
            dispatch(onUpdateSelectedContact(newContact, message));
          } else {
            const newContact: ContactObj = {
              profilePictureUrl: userImage,
              ...data,
              role: userRole[data.role],
            };
            createContact(newContact);
          }
          onClose();
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <CreateUserForm
            setUserImage={setUserImage}
            userImage={userImage}
            values={values as ContactObj}
            readOnly={readOnly}
          />
        )}
      </Formik>
    </AppDialog>
  );
};

export default CreateUser;

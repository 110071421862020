import { RapportTemplateActions, RapportTemplateObj } from "../../types/actions/RapportTemplate.action";
import {
  GET_RAPPORT_TEMPLATE_LIST
} from "../../types/actions/RapportTemplate.action";

const initialState: {
  templateList: string;
  totalTemplates: number;
} = {
  templateList: "",
  totalTemplates: 0
};

const RapportTemplateHTML = (state = initialState, action: RapportTemplateActions) => {
  switch (action.type) {
    case GET_RAPPORT_TEMPLATE_LIST:
      console.log("Reducer template list => ", action.payload.list);
      return {
        ...state,
        templateList: action.payload.list,
        totalTemplate: action.payload.total,
      };
    default:
      return state;
  }
};
export default RapportTemplateHTML;

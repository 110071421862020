import React from 'react';
import {useThemeContext} from '../../../../utility/AppContextProvider/ThemeContextProvider';
import {Box} from '@mui/material';

interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({color}) => {
  const {theme} = useThemeContext();

  return (
    <Box
      sx={{
        height: {xs: 76, sm: 90},
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        width: 'fit-content',
        // alignItems: 'center',
        // justifyContent: 'center',
        '& svg': {
          height: {xs: 60, sm: 65},
        },
      }}
      className='app-logo'
    >
      {/* <LogoImmocuisine fill={theme.palette.primary.main} width='100%'/> */}
      <Box
        sx={{
          mt: 1,
          display: {xs: 'none', md: 'block'},
          '& svg': {
            height: {xs: 25, sm: 30},
          },
        }}
      >
        {/* <LogoText fill={alpha(theme.palette.text.primary, 0.8)} /> */}
      </Box>
    </Box>
  );
};

export default AppLogo;

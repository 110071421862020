import { Contremarque } from "../models/apps/Contremarque";

export const GET_CONTREMARQUE_LIST = "GET_CONTREMARQUE_LIST";
export const UPDATE_CONTREMARQUE_DETAIL = "UPDATE_CONTREMARQUE_DETAIL";
export const CREATE_NEW_CONTREMARQUE = "CREATE_NEW_CONTREMARQUE";
export const DELETE_CONTREMARQUE = "DELETE_CONTREMARQUE";
export const UNARCHIVE_CONTREMARQUE = "DELETE_CONTREMARQUE";

export interface GetContremarquesActions {
  type: typeof GET_CONTREMARQUE_LIST;
  payload: { list: Contremarque[]; total: number };
}
export interface UpdateContremarqueActions {
  type: typeof UPDATE_CONTREMARQUE_DETAIL;
  payload: Contremarque;
}

export interface CreateContremarqueActions {
  type: typeof CREATE_NEW_CONTREMARQUE;
  payload: Contremarque;
}

export interface DeleteContremarqueActions {
  type: typeof DELETE_CONTREMARQUE;
  payload: { list: Contremarque; total: number };
}

export interface UnarchiveContremarqueActions {
  type: typeof UNARCHIVE_CONTREMARQUE;
  payload: { list: Contremarque; total: number };
}

export type ContremarqueActions =
  | GetContremarquesActions
  | UpdateContremarqueActions
  | CreateContremarqueActions
  | DeleteContremarqueActions
  | UnarchiveContremarqueActions;

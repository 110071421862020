import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ConnectedRouter } from "connected-react-router";
import AuthRoutes from "@crema/utility/AuthRoutes";
import AppContextProvider from "@crema/utility/AppContextProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppLayout from "@crema/core/AppLayout";
import configureStore, { history } from "redux/store";
import JWTAuthProvider from "./@crema/services/auth/jwt-auth/JWTAuthProvider";
import TourContextProvider from "context/TourContext";
import AbonnementContextProvider from "context/abonnement";

const store = configureStore();

const App = () => {
  return (
    <AppContextProvider>
      <Provider store={store}>
        <AppThemeProvider>
          <AppStyleProvider>
            <AppLocaleProvider>
              <TourContextProvider>
                <AbonnementContextProvider>
                  <ConnectedRouter history={history}>
                    <JWTAuthProvider>
                      <AuthRoutes>
                        <CssBaseline />
                        <AppLayout />
                      </AuthRoutes>
                    </JWTAuthProvider>
                  </ConnectedRouter>
                </AbonnementContextProvider>
              </TourContextProvider>
            </AppLocaleProvider>
          </AppStyleProvider>
        </AppThemeProvider>
      </Provider>
    </AppContextProvider>
  );
};

export default App;

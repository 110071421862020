import {
  CREATE_NEW_RESERVE,
  DELETE_RESERVE,
  GET_RESERVE_LIST,
  LEVEE_RESERVE,
  ReserveActions,
  UPDATE_RESERVE_DETAIL,
} from "../../types/actions/Reserve.action";
import { ReserveObj } from "../../types/models/apps/Reserve";

const initialState: {
  reserveList: ReserveObj[];
  totalReserve: number;
  selectedReserve: ReserveObj | null;
} = {
  reserveList: [],
  totalReserve: 0,
  selectedReserve: null,
};

const ReserveApp = (state = initialState, action: ReserveActions) => {
  switch (action.type) {
    case GET_RESERVE_LIST:
      console.log("My Reserve Get reserve list Reducer => ", action.payload);
      return {
        ...state,
        reserveList: action.payload.list,
        totalReserve: action.payload.total,
      };

    case CREATE_NEW_RESERVE:
      return {
        ...state,
        reserveList: [action.payload, ...state.reserveList],
        totalReserve: state.totalReserve + 1,
      };

    case DELETE_RESERVE: {
      console.log("My Delete Reserve list Reducer => ", action.payload);
      // const ids = action.payload.list.map(value => value.id);
      return {
        ...state,
        reserveList: state.reserveList.filter(
          (reserve) => reserve.id !== state.selectedReserve?.id
        ),
        totalReserve: state.totalReserve - action.payload.total,
      };
    }

    case UPDATE_RESERVE_DETAIL:
      return {
        ...state,
        selectedReserve: action.payload,
        reserveList: state.reserveList.map((reserve) =>
          reserve.id === action.payload.id ? action.payload : reserve
        ),
      };

    case LEVEE_RESERVE:
      return {
        ...state,
        reserveList: state.reserveList.map((reserve, index) => {
          let reserveLevee = action.payload.list.find(
            (res) => res.id === reserve.id
          );
          return {
            ...reserve,
            isChecked: reserveLevee ? reserveLevee.isChecked : reserve.isChecked,
            photos: reserveLevee ? reserveLevee.photos : reserve.photos,
          };
        }), 
      };

    default:
      return state;
  }
};
export default ReserveApp;

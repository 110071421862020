import {
  GrilleActions,
  GET_GRILLE_ACTION,
  SAVE_GRILLE_ACTION,
  UPDATE_GRILLE_ACTION,
  GET_ONE_GRILLE_ACTION,
} from "types/actions/Grille.action";
import { GrilleDto } from "types/models/apps/Grille";
import { isArray } from "util";

const initialState: {
  abonnements: GrilleDto[];
  grille: GrilleDto | undefined;
} = {
  abonnements: [],
  grille: undefined,
};

const GrilleApp = (state = initialState, action: GrilleActions) => {
  const { payload, type } = action;
  switch (type) {
    case GET_GRILLE_ACTION: {
      return {
        ...state,
        abonnements: isArray(payload) ? payload : [payload],
      };
    }
    case SAVE_GRILLE_ACTION: {
      return {
        ...state,
        abonnements: [payload, ...state.abonnements],
      };
    }

    case UPDATE_GRILLE_ACTION: {
      const abonnements = state.abonnements;
      state.abonnements.forEach((abonnement, index) => {
        if (abonnement.id === payload.id) {
          abonnements[index] = payload;
        }
      });
      return {
        ...state, 
        abonnements,
      };
    }

    case GET_ONE_GRILLE_ACTION: {
      return {
        ...state,
        grille: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default GrilleApp;

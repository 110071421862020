import Settings from "./Setting";
import Common from "./Common";
import ContactApp from "./ContactApp";
import TodoApp from "./ToDoApp";
import RapportTemplateHTML from "./RapportTemplate";
import MagasinApp from "./MagasinApp";
import ReserveApp from "./ReserveApp";
import MembershipApp from "./Membership";
import GroupApp from "./Group";
import TemplateApp from "./Template";
import ContremarqueApp from "./Contremarque";
import MetriquesApp from "./MetriquesApp";
import StripeApp from "./stripe";
import GrilleApp from "./Grille";

const reducers = {
  settings: Settings,
  common: Common,
  contactApp: ContactApp,
  todoApp: TodoApp,
  rapportTemplateApp: RapportTemplateHTML,
  magasinApp: MagasinApp,
  membersipApp: MembershipApp,
  reserveApp: ReserveApp,
  groupApp: GroupApp,
  templateApp: TemplateApp,
  contremarqueApp: ContremarqueApp,
  metriquesApp: MetriquesApp,
  stripeApp: StripeApp,
  abonnementApp: GrilleApp,
};

export default reducers;

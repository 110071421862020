import { Dispatch } from "redux";
import { AppActions } from "../../types";
import { fetchError } from "./Common";
import jwtAxios from "../../@crema/services/auth/jwt-auth";
import {
  GET_RAPPORT_TEMPLATE_LIST,
  RapportTemplateObj,
} from "../../types/actions/RapportTemplate.action";
import { useLocalStorage } from "../../shared/use-local-storage-state";

export const onGetTemplateList = () => {
  return (dispatch: Dispatch<AppActions>) => {
    // dispatch(fetchStart());
    jwtAxios
      .get("/template/html")
      .then((data) => {
        if (data.status === 200 || data.status === 201) {
          // dispatch(fetchSuccess());
          console.log("data ======", data.data);
          useLocalStorage<string>("listeTemplateHTML", data.data);
          dispatch({
            type: GET_RAPPORT_TEMPLATE_LIST,
            payload: { list: data.data, total: data.data.count },
          });
        } else {
          dispatch(
            fetchError(
              "Une erreur s'est produite lors de la recuperation des templates"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          fetchError(
            "Une erreur s'est produite lors de la recuperation des templates"
          )
        );
      });
  };
};

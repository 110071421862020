import { GrilleDto } from "types/models/apps/Grille";
import { TemplateObj } from "types/models/apps/Template";

export const GET_GRILLE_ACTION = "GET_GRILLE_ACTION";
export const SAVE_GRILLE_ACTION = "SAVE_GRILLE_ACTION";
export const UPDATE_GRILLE_ACTION = "UPDATE_GRILLE_ACTION";
export const GET_ONE_GRILLE_ACTION = "GET_ONE_GRILLE_ACTION";

export interface GetGrilleAction {
  type: typeof GET_GRILLE_ACTION;
  payload: GrilleDto;
}

export interface SaveGrilleAction {
  type: typeof SAVE_GRILLE_ACTION;
  payload: GrilleDto;
}

export interface UpdateGrilleAction {
  type: typeof UPDATE_GRILLE_ACTION;
  payload: GrilleDto;
}

export interface OnGetOneGrilleAction {
  type: typeof GET_ONE_GRILLE_ACTION;
  payload: GrilleDto;
}

export type GrilleActions =
  | GetGrilleAction
  | SaveGrilleAction
  | OnGetOneGrilleAction
  | UpdateGrilleAction;
